export const getDay = date => {
    if(!date) return null;
    const isoDate = new Date(date);
    return isoDate.getDate();
}

export const getMonth = date => {
    if(!date) return null;
    const isoDate = new Date(date);
    return isoDate.getMonth()+1;
}

export const getYear = date => {
    if(!date) return null;
    const isoDate = new Date(date);
    return isoDate.getFullYear();
}