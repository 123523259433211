import { useEffect, useState } from "react";

const useForm = (initialForm = {}) => {

    const [form, setForm] = useState(initialForm)

    const onInputChange = ({target}) => {
        const {name, value} = target
        setForm({
            ...form,
            [name] : value
        })
    }

    const onInputChangeNumber = ({target}) => {
        const {name, value} = target
        let regex = new RegExp('^[0-9]+$')

        if (regex.test(value)) {
            setForm({
                ...form, 
                [name] : value
            })
        }
    }

    useEffect(() => {
        setForm(initialForm)
    }, [initialForm])

    return {
        ...form,
        form,
        setForm, 
        onInputChange,
        onInputChangeNumber
    };
}
 
export default useForm;