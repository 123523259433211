import { useState } from "react";

const CheckboxInput = ({
    divClassName='col-span-6',
    className ="focus:ring-gary-500 h-4 w-4 text-gray-600 border-gray-300 rounded",
    label,
    id=null,
    name='name',
    description,
    linkTo = null,
    disabled,
    required,
    initialValue,
}) => {
    console.log(linkTo)
    const [value, setValue] = useState(initialValue || "");

    const onChange = (e) => {
        e.preventDefault();
        setValue(e.target.checked);
    }
    return (  
        <div className={"relative flex items-start " + divClassName}>
            <div className="flex items-center h-5">
                <input
                    id={id || name}
                    name={name}
                    className={className}
                    aria-describedby={(id || name) + "-description"}
                    type="checkbox"
                    disabled={disabled}
                    required={required}
                    checked={value}
                    onChange={onChange}
                />
            </div>
            <div className="ml-3 text-sm">
                <label htmlFor={id || name} className="font-medium text-gray-700">
                    {label}
                </label>
            <span id={(id || name) + "-description"} className="text-gray-500">
                <span className="sr-only text-title text-lg">{label} </span> {linkTo ? <a href={linkTo} rel='noreferrer' target='_blank' className="text-secondary" to={linkTo}>{description}</a> : description}
            </span>
            </div>
        </div>
    );
}
// <span className="sr-only text-title text-lg">{label} </span> {linkTo ? <a href={linkTo} rel='noreferrer' target='_blank' className="text-secondary" to={linkTo}>{description}</a> : description}
 
export default CheckboxInput;