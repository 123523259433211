const renderError = (error) => {
    let message = '';
    switch (error.name) {
        case 'InvalidPDFException':
            message = 'El documento es invalido';
            break;
        case 'MissingPDFException':
            message = 'The document no esta disponible';
            break;
        case 'UnexpectedResponseException':
            message = 'Respuesta inesperada del servicio';
            break;
        default:
            message = 'No se pudo cargar el documento';
            break;
    }

    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    backgroundColor: '#e53e3e',
                    borderRadius: '0.25rem',
                    color: '#fff',
                    padding: '0.5rem',
                }}
            >
                {message}
            </div>
        </div>
    );
};

export default renderError;