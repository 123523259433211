import Axios  from "axios"
import { alertWithButton } from "./AlertsUtils";

// HTTP STATUS CODES
export const INVALID_TOKEN_STATUS = 407;
export const EXPIRED_TOKEN_STATUS = 409;


export const setConfig = (config) => {
    config = {
        headers: { 
            'Content-Type':'application/json',
            ...(config && config.headers)
        },
        timeout: 5000,
        ...(config && config)
    }
    
    return config;
}

const encodeDataToUrl = (data) => {
    return Object.keys(data)
    .map( value => `${value}=${encodeURI(data[value])}`)
    .join('&')
}

const getUrl = (path, params) => {
    return path + (params !== null ? ('?' + encodeDataToUrl(params)) : '')
}

const responseErrorInterceptor = ((error) => {
    if (error.response.data.error.Code === EXPIRED_TOKEN_STATUS || error.response.data.error.Code === INVALID_TOKEN_STATUS) {
        const cupPermalink = sessionStorage.getItem("cupPermalink")?.split("#")[0] ?? "";
        const confirmButtonText = cupPermalink.length ? "Iniciar sesión" : "Regresar al inicio";
        const title = error.response.data.error.Code === EXPIRED_TOKEN_STATUS ? "¡Tu sesión ha caducado!" : "¡Al parecer hubo un problema con tu sesión!"
        const logout = () => {
            localStorage.removeItem("token");
            localStorage.removeItem("account");
            sessionStorage.removeItem("cupPermalink");
            window.location.href = `/${cupPermalink}`;
        };

        alertWithButton({
            title, 
            message: "Por favor, vuelve a iniciar sesión", 
            type: "error", 
            confirmButtonText,
            func: logout,
        });
    }
    return Promise.reject(error);
});

export const client = (baseUrl = null, config = null) => {
    if (baseUrl === null) {
        baseUrl = process.env.REACT_APP_API_URL + '/v' + process.env.REACT_APP_VERSION_API
    }

    if (!config) {
        config = setConfig(config)
    }

    config = {
        ...config,
        baseURL : baseUrl
    }
    return Axios.create(config)
}

export const sendRequest = (method, path, params = null, data = null, baseUrl = null, config = null) => {
    const clientInstance = client(baseUrl, config);
    clientInstance.interceptors.response.use(response => response, responseErrorInterceptor);

    return clientInstance({
        method,
        url: getUrl(path,params),
        data
    });
}

export const get = (path, params = null, baseUrl = null, config = null) => {
    return sendRequest('get', path, params, null, baseUrl , config)
}

export const post = (path, params = null, data, baseUrl = null, config = null) => {
    return sendRequest('post', path, params, data, baseUrl = null, config)
}

export const put = (path, params = null, data, baseUrl = null, config = null) => {
    return sendRequest('put', path, params, data, baseUrl = null, config)
}

export const patch = (path, params = null, data, baseUrl = null, config = null) => {
    return sendRequest('patch', path, params, data, baseUrl = null, config)
}