const BoletiaInfo = ({ cupFiles }) => {
  const boletiaLink = cupFiles?.boletiaLinkCup || cupFiles?.boletiaLinkDefault || '';
    return (
      <div>
        <p className="text-xl mb-6">Recuerda que ahora el pago por categoría será por equipo y debe realizarse en Boletia</p>
        {boletiaLink ? <a target="_blank" href={boletiaLink} style={{color: "blue"}} rel="noreferrer">Ir al Boletia.com</a> : ''}
      </div>
    )
}

export default BoletiaInfo
