import Iframe from 'react-iframe';

export default function MapSection({latitude='', longitude='', address='', place=''}) {
    const venueUrl = `https://maps.google.com/?ll=${latitude},${longitude}&z=15&t=m&output=embed`
    return (
      <div className="overflow-hidden rounded-lg divide-y divide-gray-200">
        <div className="max-w-md mx-auto py-8">
            <div className="px-4 py-5 sm:p-6 text-center text-2xl font-semibold">Ubicación</div>
            <p className="text-center text-gray-500 pb-1">{place}</p>
            <p className="text-center text-gray-400 text-sm">{address}</p>
        </div>
        <div className="sm:mx-auto" style={{maxWidth: "740px"}}>
            <div width="100%" className="pb-16">
              <Iframe url={venueUrl} width="100%" height="500rem" frameborder="0" style={{ border: "0" }} aria-hidden="false" tabindex="0" />
            </div>
        </div>
      </div>
    )
  }