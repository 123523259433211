export const getBase64 = async (file) => {
    const response = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    }).catch((err) => {
      return err
    });
    if(response.message) return file;
    return response.split(",")[1];
}
