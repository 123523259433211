import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderSign from "../components/AccessSign";
import HeadingEcyd from "../components/HeadingEcyd";
import Navbar from "../components/NavbarEcyd";
import { Attributes } from "../constants/CupData";
import CoachForm from "../containers/CoachForm";
import Footer from "../containers/FooterEcyd";
import SponsorSection from "../containers/SponsorSection";
import { addCoach, getAccount, getCoach, updateCoach } from "../lib/ApiEcyd";
import { enrollmentDatesValidation } from "../lib/helpers";
import { useCup } from "../context/cup-context";
import ErrorPage from './ErrorPage';

const CoachPage = () => {
    const navigate = useNavigate();
    const {cupPermalink, coachPermalink} = useParams();
    const { cup, getCupByPermalink } = useCup();

    useEffect(() => {
        getCupByPermalink(cupPermalink);
    }, [cupPermalink, getCupByPermalink])

    const [account, setAccount] = useState({
        loading : false,
        data : {},
        hasError : null
    })

    const [coach, setCoach] = useState({
        loading : false,
        data : {},
        hasError : null
    })

     // Initial effect with charge the component
     useEffect(() => {
        getAccount(account, setAccount)
        if (coachPermalink) {
            getCoach(coachPermalink, coach, setCoach)
        }
        // eslint-disable-next-line
    }, [])

  

    const onSubmit = async (coachValue, isUpdate) => {
        if (isUpdate) {
            //console.log("Enviando formulario para actualizar:", coachValue)
            await updateCoach(coachValue.uuid, coachValue, cup.data.contact.phoneNumber)
            .then((data) => {
                if(data?.statusText === "Created") {
                    navigate(`/${cupPermalink}/account`)
                } else {
                    getCoach(coachPermalink, coach, setCoach)
                }
            })
        } else {
            //console.log("Enviando formulario para insertar:", coachValue)
            await addCoach(coachValue, cup.data.contact.phoneNumber)
            .then((data) => {
                if(data?.statusText === "OK") {
                    navigate(`/${cupPermalink}/account`)
                } else {
                    getCoach(coachPermalink, coach, setCoach)
                }
            })
            
        }
    }

    const breadcrumbLabel =  !coachPermalink  ? "Agregar entrenador" : "Editar entrenador";

    if(account.hasError) return <ErrorPage error={account.hasError} type="account"/>
    if(cup.hasError) return <ErrorPage error={cup.hasError} type="cup"/>
    if(coach.hasError) return <ErrorPage error={coach.hasError} type="coach" link={"/" + cup.data?.permalink + "/account"}/>

    const attributes = Attributes(cup.data.permalink)

    return ( 
        (!cup.loading &&  !account.loading && cup.data && cup.data.season) &&
            <div className="max-w-5xl mx-auto px-4 sm:px-6">
                <HeaderSign
                  cupUuid={cup.data?.uuid}
                  seasonUuid={cup.data?.season?.uuid || ""}
                  categories={cup.data?.season?.categories}
                  enrollmentDates={enrollmentDatesValidation(cup?.data?.season)}
                />
                
                <Navbar logoUrl={cup.data.imageUrl || attributes.cupImgUrl} menuSections={attributes.menu}/>
                <HeadingEcyd sections={[account.data.teamName, breadcrumbLabel]} cupPermalink={cup.data.permalink} breadcrumb/>
                
                <CoachForm 
                    account={account.data}
                    onSubmit={onSubmit}
                    coachValue={!coachPermalink ? {} : coach.data}
                    categories={cup.data.season.categories || []}
                    isUpdate={!coachPermalink  ? false : true}
                    ageLimit={cup.data?.season?.ageCoachLimit}
                    cup={cup}
                />

                <section id="sponsors" className="mt-10 bg-secondary">
                    <SponsorSection sponsors={cup.data.sponsors} titleStyle={"text-title-primary text-center leading-8 tracking-tight sm:text-4xl sm:tracking-tight"}/>
                </section>
                <Footer logoUrl={cup.data.imageUrl || attributes.cupImgUrl} menuSections={attributes.menu} termsAndConditionsUrl={cup.data?.files?.termsAndConditionsDocUrl} />
            </div> 
    );
}
 
export default CoachPage;
