import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { CurrentYear } from '../constants/Numbers';
import ApiErrorMapper from './ApiErrorMapper';
import { ErrorMessages } from '../constants/ExtraPlayerErrorMsg';


export const messageAlert = (title='', message = '', type = 'success', showConfirmButton = false, confirmButtonText = 'OK', timer = 3000) => {
    const MySwal = withReactContent(Swal) 

    if (showConfirmButton) {
        timer = null
    }

    MySwal.fire({
        titleText: `${title}`,
        html : message,
        footer: `® ${CurrentYear} ECYD All rights reserved @Powered By Pokal`,
        icon: `${type}`,
        showConfirmButton,
        ...(timer !== 0 && {timer}),
        customClass: {
            confirmButton: 'btn-green w-24',
            cancelButton: 'btn-pink bg-gray-500 w-24'
        },
        buttonsStyling: false,
        ...(showConfirmButton && { confirmButtonText })
    })
}

export const messageDelete = (title='', message = '', type = 'warning', func = null) => {
    const MySwal = withReactContent(Swal) 

    MySwal.fire({
        titleText: `${title}`,
        //text : `${message}`,
        html : message,
        footer: `® ${CurrentYear} ECYD All rights reserved @Powered By Pokal`,
        icon: `${type}`,
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        customClass: {
            confirmButton: 'btn-green w-24',
            cancelButton:'btn-pink w-24 m-5'
        },
        buttonsStyling: false,

      }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (func && result.isConfirmed) {
                func()
            }
        })
}

export const messageSuccess = (title='', message='') => {
    messageAlert(title, message, 'success', true)
}

export const messageError = (title='', errorText='', error=null, service=null) => {
    let errorMessage = ''
    if (error) {
        errorMessage = error.data.error.Code && service ? ApiErrorMapper(service, error.data.error.Code) 
        : error.data.error ? 
            `${error.data.error.Code} - ${error.data.error.Message}`
        : error.data
    }

    if (service === "player") {
        if (!ErrorMessages[error.data.error.Code]) {
            errorText='';
        }
    }
    
    const errorHtml = (
        <>
            <p className='text-primary text-gray-600'>{errorMessage}</p>
            <br/>
            <p className='text-title-subtitle'>{errorText}</p>
        </>
    )
        
    messageAlert(title, errorHtml, 'error', true)
}

export const loadingMessage = (
    title = "", 
    success = { 
        title: ""
    }, 
    error = { 
        title: '', 
        errorText:'', 
        error: null 
    }, service) => {
    const MySwalLoading = withReactContent(Swal);

    MySwalLoading.fire({
        title,
        allowEscapeKey: false,
        allowOutsideClick: false,
        timerProgressBar: true,
        footer: `® ${CurrentYear} ECYD All rights reserved @Powered By Pokal`,
        didOpen: () => {
            MySwalLoading.showLoading();
        },
    }).then((res) => {
        MySwalLoading.hideLoading()
        if(res.result === "success") {
            messageSuccess(success.title)
        } else if (error.title) {
            messageError(error.title, error.errorText, res.error, service)
        }
    })

    return MySwalLoading;
}; 

export const messageAlertTimer = (title = '', timer = 500, showProgressBar = true, ) => {
    let timerInterval;
    return Swal.fire({
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        title,
        timer,
        timerProgressBar: showProgressBar,
        didOpen: () => {
            Swal.showLoading();
        },
        willClose: () => {
            clearInterval(timerInterval)
        }
    })
}

export const messageAlertCallback = (title = '', message = '', type = 'success',  showCancelButton = false, confirmButtonText = 'OK', cancelButtonText = "Cancelar") => {
  return Swal.fire({
        title,
        html: message,
        icon: type,
        showCancelButton,
        confirmButtonColor: '#3085d6',
        confirmButtonText,
        cancelButtonText,
    })
}

export const alertWithButton = async ({
    title = "",
    message = "",
    type = "success",
    confirmButtonText = "OK",
    func = null
  }) => {
    return Swal.fire({
      title,
      html: message,
      icon: type,
      confirmButtonColor: "#3085d6",
      confirmButtonText,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (func && result.isConfirmed) func();
    });
};

export const SWAL_DISMISS_TIMER = Swal.DismissReason.timer;
