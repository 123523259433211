import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon} from '@heroicons/react/outline'
import { NavHashLink as Link } from 'react-router-hash-link';

const Navbar = ({logoUrl='', menuSections=[]}) => {
    return ( 
      <Popover className="relative bg-white">
        <div className="flex justify-between items-center border-b-2 border-gray-100 py-2 md:justify-start md:space-x-10">
          {/* Ecyd Logo */}
          <div className='flex justify-start lg:w-0 lg:flex-1'>
            <Link to="/" className="flex">
              <span className="sr-only">ECYD Logo</span>
              <img
                className="h-8 w-auto sm:h-10"
                src={logoUrl}
                alt="ecyd-logo"
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Abrir menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
            <Popover.Group as="nav" className="flex space-x-10">
            {menuSections.map((section) => (
              <Link key={section.title} to={section.url} className="text-primary font-medium hover:text-secondary truncate">
                {section.title}
              </Link>
            ))}
            {logoUrl.includes("ecyd") &&
              <>
              <a key="Regnum Christi" href="https://www.regnumchristi.mx" className="text-primary font-medium hover:text-secondary truncate" rel='noreferrer' target='_blank'>
                Regnum Christi
              </a>
              <a key="ECYD" href="https://www.ecyd.org" className="text-primary font-medium hover:text-secondary truncate" rel='noreferrer' target='_blank'>
                ECYD
              </a>
              </>
            }
            
            </Popover.Group>
          </div>
        </div>
        
        {/* Trancision cuando la pantalla se hace mas chica */}
        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-8 w-auto sm:h-10"
                      src={logoUrl}
                      alt="ecyd-logo"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Cerrar menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="py-6 px-5">
                <div className="grid grid-cols-1 gap-4">
                {menuSections.map((section) => (
                  <a key={section.title} href={section.url} className="text-primary font-medium hover:text-secondary">
                    {section.title}
                  </a>
                ))}
                </div>  
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    );
}
 
export default Navbar;