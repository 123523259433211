import React, { useEffect } from "react";

export default function PrivacyAbt() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Add smooth scrolling behavior
    });
  }, []);
  return (
    <div>
      <div className="relative pb-5 pt-10 static">
        {" "}
        {/* <img className="absolute opacity-25 top-0" src="https://static.vecteezy.com/system/resources/previews/001/204/025/original/soccer-png.png" alt="about" /> */}
        <div className="flex justify-center items-center">
          <div className="w-16 h-16"></div>
          <h2 className="text-black leading-8 tracking-tight sm:text-4xl sm:tracking-tight">
            Política de Privacidad
          </h2>
        </div>
        <br></br>
        <ol className="list-decimal leading-10 px-6 text-justify">
          <p>
            Política de Privacidad para Pokal (sonder.mut SA de CV)
          </p>
          <p>
            Última actualización: 2 de Octubre 2023
          </p>
          <br></br>
          <p>
            Bienvenido a Pokal. Esta Política de Privacidad explica cómo
            recopilamos, usamos, procesamos y divulgamos tu información,
            incluyendo datos personales, cuando accedes o utilizas nuestra
            app/sitio web y servicios.
          </p>
          <br></br>
          <li>
            <p>
              <strong className="text-black">Alcance: </strong>
              Esta política aplica a los datos personales procesados por Pokal,
              incluyendo datos recopilados a través de nuestro sitio web,
              comunicaciones por correo electrónico y cualquier servicio
              relacionado.
            </p>
          </li>
          <br></br>
          <li>
            <h3 className="text-black text-underline font-bold">
              Información que Recopilamos
            </h3>
            <ol>
              <li>
                <strong className="text-black">
                  Información que nos proporcionas:{" "}
                </strong>
                <br></br>
                Información de Cuenta: Al registrarte, recopilamos tu nombre,
                dirección de correo electrónico, contraseña y otra información
                necesaria.
                <br></br>
                Información de Perfil: Podrías proporcionar fotos, biografía y
                otros detalles personales. Información de Transacciones:
                Recopilamos detalles de transacciones relacionadas con tu uso de
                nuestros servicios.
                <br></br>
                Comunicaciones: Al comunicarte con nosotros o usar nuestra
                plataforma para comunicarte con otros usuarios, almacenamos una
                copia de tus comunicaciones.
              </li>
              <li>
                <strong className="text-black">
                  Información que Recopilamos Automáticamente:{" "}
                </strong>
                <br></br>
                Datos de Registro: Nuestros servidores registran automáticamente
                información sobre tu interacción con nuestros servicios, como la
                dirección IP, tipo de navegador, páginas visitadas, información
                del dispositivo y marcas temporales.
                <br></br>
                Datos de Uso: Recopilamos datos sobre cómo y cuándo accedes o
                usas nuestros servicios. Cookies: Utilizamos cookies y
                tecnologías similares para reconocerte y proporcionarte una
                experiencia personalizada. servicios.
                <br></br>
              </li>
              <li>
                <strong className="text-black">Datos de terceros: </strong>
                <br></br>
                Podemos obtener información sobre ti de terceros, como
                plataformas de redes sociales o servicios de agregación de
                datos.
              </li>
            </ol>
          </li>
          <br></br>
          <li>
            <p className="text-black text-underline font-bold">
              {" "}
              Cómo Usamos tu Información
            </p>
            <p>
              {" "}
              <strong className="text-black">Prestación del Servicio: </strong>
              Para proveer y mejorar nuestros servicios.
            </p>
            <p>
              {" "}
              <strong className="text-black">Comunicaciones: </strong>
              Para comunicarnos contigo, enviar materiales promocionales y
              responder a tus consultas.
            </p>
            <p>
              {" "}
              <strong className="text-black">Legal y Administrativo: </strong>
              Para cumplir obligaciones legales y procesar transacciones.
            </p>
          </li>
          <br></br>
          <li>
            <h3 className="text-black text-underline font-bold">
              Compartir y Divulgar Información
            </h3>
            <p>
              {" "}
              <strong className="text-black">Proveedores de Servicio: </strong>
              Podemos compartir datos con terceros que realizan servicios en
              nuestro nombre.{" "}
            </p>
            <p>
              <strong className="text-black">Legal y Cumplimiento: </strong>
              Podemos divulgar tu información si la ley nos obliga a hacerlo.
            </p>
            <p>
              <strong className="text-black">Protección de Derechos: </strong>
              Podemos divulgar información para proteger los derechos, la
              propiedad o la seguridad de Pokal, nuestros empleados, usuarios u
              otros.
            </p>
            <p>
              <strong className="text-black">
                Transferencias Comerciales:
              </strong>{" "}
              En caso de una fusión o venta, la información del usuario podría
              ser uno de los activos transferidos.
            </p>
          </li>
          <br></br>
          <li>
            <h3 className="text-black text-underline font-bold">
              Retención de datos:
            </h3>
            Conservamos tu información el tiempo que sea necesario para los
            fines para los que la recopilamos, o si la ley lo requiere.
          </li>
          <br></br>
          <li>
            <h3 className="text-black text-underline font-bold">
              Tus Opciones
            </h3>
            Información de Cuenta: Puedes actualizar o eliminar tu información
            de cuenta en cualquier momento.
            <br></br>
            <p>
              <strong className="text-black">Cookies: </strong>
              Puedes deshabilitar las cookies a través de tu navegador.
            </p>
            <p>
              <strong className="text-black">
                Comunicaciones Promocionales:{" "}
              </strong>
              Puedes optar por no recibir comunicaciones promocionales.
            </p>
          </li>
          <br></br>
          <li>
            <h3 className="text-black text-underline font-bold">Seguridad</h3>
            <p>
              Empleamos diversas medidas de seguridad para proteger tus datos,
              pero no podemos garantizar la seguridad total de los datos
              transmitidos o almacenados.
            </p>
          </li>
          <br></br>
          <li>
            <p>
              <strong className="text-black">Cambios en esta Política:</strong>{" "}
              Publicaremos cualquier cambio en esta Política de Privacidad en
              esta página. Si los cambios son significativos, proporcionaremos
              un aviso más destacado.
            </p>
          </li>
          <br></br>
          <li>
            <p>
              <strong className="text-black">Contáctanos:</strong> Para
              cualquier pregunta o preocupación sobre esta política o nuestras
              prácticas de datos, contáctanos en:{" "}
              <strong className="text-black">contacto@sondermut.com</strong>
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
}
