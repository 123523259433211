// Constant info
const CONTACT_NUMBER = process.env.REACT_APP_CONTACT_NUMBER;
/** Alerts displaying this messages accept html! */

export const CONTACT_INFO = `Por favor comuníquese al número: ${ CONTACT_NUMBER }`;
export const DEFAULT_INTERNAL_ERROR_MESSAGE = `Parece que hubo problemas con el servidor. ${CONTACT_INFO}`;

export const ErrorMessages = {
    register: {
        500: DEFAULT_INTERNAL_ERROR_MESSAGE,
        403: `Lo sentimos, el nombre de equipo que intentas registrar ya existe, favor de cambiarlo`,
        404: `Lo sentimos, este correo electrónico no puede ser usado, intenta con otro`
    },
    login: {
        411: `Es necesario que primero confirmes tu cuenta para poder iniciar sesión.`,
        500: DEFAULT_INTERNAL_ERROR_MESSAGE,
        501: `Lo sentimos, tu correo electrónico y/o contraseña son incorrectos.`,
        509: `Lo sentimos, tu correo electrónico y/o contraseña son incorrectos.`,
        515: `Lo sentimos, parece que hay problemas con tu cuenta. ${CONTACT_INFO}.`
    },
    payment: {
        510: `Lo sentimos, cupo lleno, te esperamos la próxima edición.`,
        511: `Ya no se pueden inscribir equipos, después del cierre de pago.`,
        512: `Ya cerramos inscripciones, te esperamos la próxima edición.`,
        500: `Por el momento no puedes realizar el pago. Comunícate al número ${'var'}.`,
        501: `El email proporcionado no es el mismo con el que se generó el código en Boletia`,
        509: `El código proporcionado no es válido`,
        537: `El código proporcionado ya ha sido canjeado`,
        538: `La categoría seleccionada no es la misma con la que se generó el código en Boletia`,
        539: `Su cuenta no forma parte de la categoría seleccionada`,
		540: `El pago de la temporada se ha realizado anteriormente`,
    },
    account: {
        401: `Error con la Cuenta, vuelva a ingresar`,
        404: `Lo sentimos, esta página no existe.`,
        417: `Lo sentimos, no se pudieron copiar los jugadores debido a que no se realizó el pago la temporada pasada.`,
        500: DEFAULT_INTERNAL_ERROR_MESSAGE,
        501: `Lo sentimos, hubo un error copiando el entrenador.`,
        509: `Lo sentimos, esta Cuenta no existe.`,
    },
    cup: {
        401: `Error con la Copa, vuelva a ingresar`,
        404: `Lo sentimos, esta página no existe.`,
        500: DEFAULT_INTERNAL_ERROR_MESSAGE,
        509: `Lo sentimos, esta Copa no existe.`,
    },
    coach: {
        404: `Lo sentimos, esta página no existe.`,
        500: DEFAULT_INTERNAL_ERROR_MESSAGE,
        509: `Lo sentimos, Coach no localizado.`,
    },
    player: {
        402: `Un jugador ya existe con este curp`,
        404: `Lo sentimos, esta página no existe.`,
        418: `Lo sentimos, tu jugador no cumple con el rango de edad.`,
        500: DEFAULT_INTERNAL_ERROR_MESSAGE,
        502: `Faltan los datos basicos de nombre y apellido del jugador`,
        509: `Lo sentimos, Jugador no localizado.`,
        516: `Este jugador ya existe con el mismo nombre o curp`,
        535: `No se permiten refuerzos en esta categoria`,
        536: `No se permiten más refuerzos en tú equipo`,
        543: `Hay otro jugador con el mismo número de playera en el equipo, favor de cambiarlo`
    },
    document: {
        404: `Lo sentimos, este documento no existe.`,
        500: DEFAULT_INTERNAL_ERROR_MESSAGE,
        509: `Lo sentimos, Documento no localizado.`,
    },
    resetPassword: {
        404: `Lo sentimos, El servicio no se encuentra disponible.`,
        407: `Lo sentimos, Enlace de Autenticación no válida.`,
        408: `Lo sentimos, Sesión incorrecta intente de nuevo`,
        409: `Lo sentimos, La sesión terminó intente de nuevo.`,
        500: DEFAULT_INTERNAL_ERROR_MESSAGE,
    },
    requestResetPassword: {
        404: `Lo sentimos, El servicio no se encuentra disponible.`,
        407: `Lo sentimos, Autenticación no válida.`,
        408: `Lo sentimos, Enlace Requerido.`,
        409: `Lo sentimos, Enlace Expirado.`,
        410: `Lo sentimos, La dirección de correo electrónico no está asignada a ninguna cuenta.`,
        500: DEFAULT_INTERNAL_ERROR_MESSAGE,
    }
}
