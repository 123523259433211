import { useNavigate } from "react-router-dom";
import EventBus from "../lib/EventBus";
import { useCup } from "../context/cup-context";

const ContentWithImage = ({
  title = "",
  description = "",
  image,
  cupPermalink,
  enrollmentDates,
}) => {
  const token = localStorage.getItem("token") || null;
  const navigate = useNavigate();
  const handleButton = () => {
    if (token === null) EventBus.$dispatch("openModal", "register");
    else navigate(`/${cupPermalink}/account`);
  };
  const { getCupActiveSession, getCupColor } = useCup();
  const cupSession = getCupActiveSession();

  return (
    <div className="bg-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className="rounded-lg object-cover object-center"
                    src={image}
                    alt="content"
                    width={1184}
                    height={1376}
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
              <div>
                <h3 className="text-center mt-2 text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight">
                  {title}
                </h3>
              </div>
            </div>
            <div className="text-justify mt-5 text-primary max-w-prose mx-auto lg:max-w-none">
              <p className="text-lg">{description}</p>
            </div>
            {(enrollmentDates &&
              cupSession !== null &&
              cupSession.permalink === cupPermalink) ||
            (enrollmentDates && cupSession) === null ? (
              <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <button
                    style={{ backgroundColor: getCupColor() }}
                    type="button"
                    onClick={handleButton}
                    className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white md:py-4 md:text-lg md:px-10`}
                  >
                    {token ? `Mi cuenta` : `Regístrate`}
                  </button>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentWithImage;
