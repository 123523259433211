import { messageError, messageSuccess, loadingMessage } from "./AlertsUtils"
import { get, put, post, sendRequest, patch } from "./AxiosClient"

export const healthCheck = async (state, setState) => {
    try {
        const response = await get(`/health`)
        //console.log("realizando peticion", response)
        setState({
            ...state,
            data : response.data,
            loading : false
        })
    } catch (error) {
        setState({
            loading : false,
            hasError : error
        })
    }
}

export const getCupList = async () => {
    try {
        const { data } = await get(`/cups`);
        return data;
    } catch (error) {
        return error
    }
}

export const getCup = async (permalink) => {
    try {
        const { data } = await get(`/cups/permalink/${permalink}`);
        return data;
    } catch (error) {
        return error
    }
}

export const getSponsorList = async () => {
    try {
        const { data } = await get(`/sponsors`);
        return data;
    } catch (error) {
        return error
    }
}

export const getAccount = async (state, setState) => {
    try {
        const token = localStorage.getItem("token");
        const config = { headers: { "Authorization": `Bearer ${ token }` } };
        const response = await get(`/accounts/me`, null, null, config);
        setState({
            data : response.data.result,
            hasError: null,
            loading : false
        })
    } catch (error) {
        setState({
            loading : false,
            hasError : true,
            data: error.response.data,
        })
    }
}

export const updateAccount = async (account, phone='') => {
    const swal = loadingMessage(
        "Actualizando Cuenta",
        {
            title: "Tú cuenta se actualizó correctamente",
        },
        {
            title: `Error al actualizar tu cuenta`,
            errorText:`Hubo un error al actualizar tu información  por favor comunícate al ${phone}`, 
        });
    try {
        const token = localStorage.getItem("token");
        const config = { headers: { "Authorization": `Bearer ${ token }` } };
        const response = await put(`/accounts/${account.Uuid}`, null, account, null, config)

        swal.close({
            result: "success"
        })
        //console.log("realizando peticion", response)
        return response.data;
    } catch (error) {
        swal.close();
        return error.response.data;
    }
}

export const addPayment = async (payment, state = null, setState = null) => {
    try {
        const token = localStorage.getItem("token");
        const config = { headers: { "Authorization": `Bearer ${ token }` } };
        const response = await post(`/payments`, null, payment, null, config)
        if (state !== null) {
            setTimeout(() => {
                setState({
                    ...state,
                    data : response.data.result,
                    status: response.status,
                    loading : false
                });
            }, 1000);
        }
        return response.data.result;
    } catch (error) {
        if (state !== null) {
            setState({
                loading : false,
                hasError : error,
                status: error.response.status,
            });
        }
        console.log("Error: ", error.response.data.error);
        return error;
    }
}

export const addExtraPlayer = async (paymentUuid, state = null, setState = null) => {
    try {
        const token = localStorage.getItem("token");
        const config = { headers: { "Authorization": `Bearer ${ token }` } };
        const response = await patch(`/payments/${paymentUuid}/extraPlayers`, null, {}, null, config)
        if (state !== null) {
          setTimeout(() => {
              setState({
                  ...state,
                  data : response.data.result,
                  status: response.status,
                  loading : false
              });
          }, 1000);
      }
      return response.data.result;
  } catch (error) {
      if (state !== null) {
          setState({
              loading : false,
              hasError : error,
              status: error.response.status,
          });
      }
      console.log("Error: ", error.response.data.error);
      return error;
  }
}

export const updatePayment = async (payment, phone='') => {
    try {
        const token = localStorage.getItem("token");
        const config = { headers: { "Authorization": `Bearer ${ token }` } };
        await put(`/payments/${payment.uuid}`, null, payment, null, config)
    } catch (error) {
        messageError(
            `Error al guardar tu pago`,
            `Ocurrió un error al guardar tu pago, por favor comunícate al ${phone}`,
            error.response
        )
    }
}

export const createAccount = async (data) => {
    try {
        const response = await post(`/register`, null, data);
        return response.data;
    } catch (error) {
        //console.log(error);
        return error.response;
    }
}

export const loginAccount = async (data) => {
    try {
        const response = await post(`/login`, null, data);
        localStorage.setItem('token', response.data.result);
        return response.data;
    } catch (error) {
        //console.log(error);
        return error.response;
    }
}

export const DeleteEntity = async (entity, uuid) => {
    try {
        const entityStr = entity === 'player' ? 'players' : 'coaches' 
        const token = localStorage.getItem("token");
        const config = { headers: { 
            'Content-Type':'application/json',
            "Authorization": `Bearer ${ token }`
         } };
        await sendRequest('delete', `/${entityStr}/${uuid}`, null, '{}', null, config)
        //console.log("deleting player", response)
        messageSuccess('Se elimino correctamente')
    } catch (error) {
        messageError(
            `Error al eliminar`,
            `Hubo un error al eliminar tu información`,
            error.response
        )
    }
}
 
export const addPlayer = async (player, phone='') => {
    const swal = loadingMessage(
        "Guardando Jugador",
        {
            title: "Jugador agregado correctamente",
        },
        {
            title: `Error al agregar jugador`,
            errorText:`Ocurrió un error, por favor comunicate al ${phone}`, 
        },
        'player'
    );
    try {
        const token = localStorage.getItem("token");
        const config = { 
            headers: { 
                "Authorization": `Bearer ${ token }`,
            } 
        };
        // const response = await setTimeoutResponse(playerFixture);
        const response = await post(`/players`, null, player, null, config);
        swal.close({
            result: "success"
        })
        //console.log("Sending player request to API...", response)
        return response;
    } catch (error) {
        swal.close({
            result: "error",
            service: 'player',
            error: error.response
        })
    } 
}

export const updatePlayer = async (uuid, player, phone='') => {
    const swal = loadingMessage(
        "Guardando Jugador",
        {
            title: "Jugador acualizado correctamente",
        },
        {
            title: `Error al actualizar jugador`,
            errorText:`Ocurrió un error, por favor comunicate al ${phone}`,
        }, 'player');
    try {
        const token = localStorage.getItem("token");
        const config = { headers: { "Authorization": `Bearer ${ token }` } };
        
        const response = await put(`/players/${uuid}`, null, player, null, config)
        swal.close({
            result: "success"
        })
        //console.log("Sending player request to API...", response)
        return response;
    } catch (error) {
        swal.close({
            service: 'player',
            result: "error",
            error: error.response
        })
    }
}

export const getPlayer = async (playerPermalink, state, setState, phone) => {
    try {
        const token = localStorage.getItem("token");
        const config = { headers: { "Authorization": `Bearer ${ token }` } };
        const response = await get(`/players/permalink/${playerPermalink}`, null, null, config)
        //console.log("Sending player request to API...", response)
        setState({
            hasError: null,
            data : response.data.result,
            loading : false
        })
    } catch (error) {
        messageError(
            `Error al obtener jugador entrenador`,
            `Ocurrió un error, por favor comunicate al ${phone}`,
            error.response
        )
        setState({
            loading : false,
            hasError : error
        })
    }
}

export const getCoach = async (permalink, state, setState) => {
    try {
        const token = localStorage.getItem("token");
        const config = { headers: { "Authorization": `Bearer ${ token }` } };
        const response = await get(`/coaches/permalink/${permalink}`, null, null, config)
        //console.log("realizando peticion", response)
        setState({
            hasError: null,
            data : response.data.result,
            loading : false
        })
    } catch (error) {
        setState({
            loading : false,
            hasError : error
        })
    }
}

export const addCoach = async (coach, phone='') => {
    const swal = loadingMessage(
        "Guardando Entrenador",
        {
            title: "Entrenador agregado correctamente",
        },
        {
            title: `Error al agregar entrenador`,
            errorText:`Ocurrió un error, por favor comunicate al ${phone}`, 
            error: null
        }
    );
    try {
        const token = localStorage.getItem("token");
        const config = { headers: { "Authorization": `Bearer ${ token }` } };
        const response = await post(`/coaches`, null, coach, null, config)
        //console.log("Sending player request to API...", response)
        swal.close({
            result: "success"
        });
        return response;
    } catch (error) {
        swal.close({
            result: "error",
            error: error.response
        })
    }
}

export const updateCoach = async (uuid, coach, phone='') => {
     const swal = loadingMessage(
        "Guardando Entrenador",
        {
            title: "Entrenador actualizado correctamente",
        },
        {
            title: `Error al actualizar entrenador`,
            errorText:`Ocurrió un error, por favor comunicate al ${phone}`, 
            error: null
        }
    );
    try {
        const token = localStorage.getItem("token");
        const config = { headers: { "Authorization": `Bearer ${ token }` } };
        const response = await put(`/coaches/${uuid}`, null, coach, null, config)
        //console.log("Sending player request to API...", response)
        swal.close({
            result: "success"
        });        return response;
    } catch (error) {
        swal.close({
            result: "error",
            error: error.response
        })
    }
}

export const activateAccount = async (token) => {
    try {
        const config = { headers: { "Authorization": `Bearer ${ token }` } };
        return await patch(`/accounts/active`, null, null, null, config);
    } catch (error) {
        return error;
    }
}

export const requestTokenResetPassword = async (data) => {
    try {
        const response = await post(`/login/changePassword`, null, data)
        return response;
    } catch (error) {
        return error.response
    }
}

export const resetPassword = async (data, token, phone) => {
    try {
        const config = { headers: { "Authorization": `Bearer ${ token }` } };
        const response = await patch(`/login/changePassword`, null, data, null, config)
        
        return response;
    } catch (error) {
        return error.response
    }
}

export const addAccountToSeason = async (data) => {
  const waitingMessage = "Inscribiendo cuenta a la temporada...";
  const successMessage = { title: "Tu cuenta ha sido inscrita a la nueva temporada" };
  const errorMessage = {
    title: `Error al actualizar tu cuenta`,
    errorText:`Hubo un error al actualizar tu información  por favor intenta más tarde`, 
  };
  const swal = loadingMessage(waitingMessage, successMessage, errorMessage);
  try {
      const token = localStorage.getItem("token");
      const config = { headers: { "Authorization": `Bearer ${ token }` } };
      const response = await post(`/season-has-accounts`, null, data, null, config)
      swal.close({
          result: "success"
      });
      return response.data;
  } catch (error) {
      swal.close();
      return error.response.data;
  }
}

export const copyOldPlayersInNewSeason = async () => {
  const swal = loadingMessage(
    "Copiando información...", { title: "Jugadores copiados correctamente" },
    {
      title: `Error al copiar jugadores`,
      errorText:`Hubo un error al mientras se copiaba la información de tus jugadores`, 
    });
  try {
      const token = localStorage.getItem("token");
      const config = { headers: { "Authorization": `Bearer ${ token }` } };
      const response = await post(`/previous-accounts`, null, null, null, config)
      swal.close({
          result: "success"
      });
      return response;
  } catch (error) {
      swal.close({
          result: "error"
      });
      return error.response;
  }
}

export const redeemBoletiaPaymentCode = async (data) => {
    const swal = loadingMessage(
      "Validando código de pago Boletia...",
      { title: "Código canjeado correctamente!" }
    );
    try {
        const token = localStorage.getItem("token");
        const config = { headers: { "Authorization": `Bearer ${ token }` } };
        const response = await post(`/payments/external`, null, data, null, config)

        swal.close({
            result: "success"
        });
        return response;
    } catch (error) {
        swal.close({
            result: "error"
        });
        return error.response;
    }
  }
  