import { useContext, createContext, useState, useEffect } from "react";
import { getCupList, getSponsorList } from "../lib/ApiEcyd";

const initialState = {
    cupList: {
        loading : true,
        list : [],
        error: null,
    },
    sponsorList: {
        loading : true,
        list : [],
        error: null,
    }
};

const DetailsContext = createContext(initialState);

export const useDetails = () => useContext(DetailsContext);

export default function DetailsProvider({ children }) {
    const [cupList, setCupList] = useState({
        loading : true,
        list : [],
        error: null,
    });
    const [sponsorList, setSponsorList] = useState({
        loading : true,
        list : [],
        error: null,
    });

    useEffect(() => {
        async function fetchData() {
            const data = await getCupList();
            if(data.success) {
                setCupList({
                    loading : false,
                    list: data.result,
                    error: null
                });
            } else {
                setCupList({
                    loading : false,
                    error: data,
                    list: []
                });
            }
        }
        fetchData();
    }, [])

    useEffect(() => {
        async function fetchData() {
            const data = await getSponsorList();
            if(data.success) {
                setSponsorList({
                    loading : false,
                    list: data.result,
                    error: null
                });
            } else {
                setSponsorList({
                    loading : false,
                    error: data,
                    list: []
                });
            }
        }
        fetchData();
    }, [])
    

    return (
        <DetailsContext.Provider value={{ cupList, sponsorList }}>
            {children}
        </DetailsContext.Provider>
    );
}