import { useNavigate, useParams } from 'react-router-dom';
import { messageAlertCallback } from '../lib/AlertsUtils';
import { activateAccount } from '../lib/ApiEcyd';
import landing from "../resources/landing.json";

const AccountActivationPage = () => {
    const redirect = useNavigate();
    const { token, cupPermalink } = useParams();
    let openLogin = '';
    let buttonText = 'Entendido';
    let alertType = 'error';
    let message = 'No se pudo activar tu cuenta <br> por favor comuníquese al <a href="tel:5613750312"><b>5613750312</b></a>';

    setTimeout(async() => {
        const response = await activateAccount(token);

        if (response.status === 201) {
            message = 'Cuenta activada con éxito, ahora puedes iniciar sesíon';
            openLogin = '?openLogin=true';
            buttonText = 'Ir al login';
            alertType = 'success';
        }

        messageAlertCallback(message, '', alertType, false, buttonText)
            .then(() => {
                redirect(`/${cupPermalink}${openLogin}`);
            });
    }, 1000);

    return (  
        <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
            <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex-shrink-0 flex justify-center">
                    <a href="/" className="inline-flex">
                        <span className="sr-only">ecyd-logo</span>
                        <img
                            className="w-auto"
                            src={landing.logoUrl}
                            alt=""
                        />
                    </a>
                </div>
                <div className="py-16">
                    <div className="text-center">
                        <h1 className="mt-2 text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl sm:tracking-tight loading-dots">
                            Activando cuenta, por favor espere
                        </h1>
                    </div>
                </div>
            </main>
        </div>
    );
}
 
export default AccountActivationPage;