import { useEffect } from "react";
import { useRef } from "react";
import ImageSimpleForm from "../components/ImageSimpleForm";
import Input from "../components/Input"
import useForm from "../hooks/UseForm";
import { messageError } from "../lib/AlertsUtils";
import { getYearsBetweenDates } from "../lib/DateUtils";
import { convertDateToUTC } from "../utils/getDateUTC";
import { validateDate } from "../utils/validateDate";
import {titleDay, titleMoth, titleYear, titleNumber, titleCurpRfc,
    validateDay, validateMonth, validateYear, validateName, validateNumber, validateCURPRFC} from "../utils/validations";
import { getExtensionFile} from "../utils/getExtensionFile";
import { getBase64} from "../utils/convertToBase64";
import { ErrorMessages} from "../constants/ErrorMessages";
import { useCup } from "../context/cup-context";
const CoachForm = ({coachValue, account, onSubmit, categories=[], isUpdate=false, ageLimit=0, cup}) => {
    
    const {form, setForm, onInputChange, 
        name, lastName, secondLastName, day, 
        month, year, phoneNumber, identifier, 
        idUrl, dateBirth, idBase64,
        sizeTop, sizeBottom} = useForm(coachValue)
    const validateBirthDataRef = useRef();
    const validateDataRef = useRef();
    const sizesRef = useRef();
    const { getCupColor } = useCup();

    const requiredSizes =cup?.data?.season?.requiredSizes || false;
    const sizes = ['Selecciona una talla', 'XS', 'S', 'M', 'L', 'XL'];

    const birthDateClassname = "col-span-6 sm:col-span-6 lg:col-span-6"

    useEffect(() => {
        if (dateBirth) {
            // TODO: Create a function
            let dayValue = new Date(coachValue.dateBirth).getDate()
            let monthValue = new Date(coachValue.dateBirth).getMonth() + 1
            const yearValue = new Date(coachValue.dateBirth).getFullYear() 

            if (dayValue < 9) {
                dayValue = '0' + dayValue
            } 
            if (monthValue < 9) {
                monthValue = '0' + monthValue
            } 

            setForm({
                ...form,
                day : dayValue,
                month : monthValue,
                year : yearValue
            })

            //console.log("date", coachValue.dateBirth, dayValue, monthValue, yearValue)
        }
        
        // eslint-disable-next-line 
    }, [dateBirth])

    const isComplete = () => {
        if (!day || day ===''
            ||!month || month ===''
            || !year || year ===''
            || !identifier || identifier === ''
            || !phoneNumber || phoneNumber === ''
            || (!idUrl && !idBase64)) {
            return false
        }

        return true
    }

    const onValidateAndSubmit = (e) => {
        e.preventDefault()

        const complete = isComplete()
        if (!complete) {
            messageError("Error te falta completar el registro")
            return
        }
        onSubmitButton(e)
    }

    const onSubmitButton = e => {
        e.preventDefault()
        if (year && month && day) {
            if(!validateDate(year, month, day)) {
                validateDataRef.current.className = "my-5 col-span-6 sm:col-span-6 lg:col-span-6 text-center";
                validateDataRef.current.scrollIntoView({
                    block: "center"
                })
                return
            } else {
                validateDataRef.current.className = "my-5 col-span-6 sm:col-span-6 lg:col-span-6 text-center hidden";
            }

            const birthDate = convertDateToUTC(year, month, day);
            const years = getYearsBetweenDates(new Date(birthDate), new Date())

            if(years < ageLimit) {
                validateBirthDataRef.current.className = birthDateClassname;
                validateBirthDataRef.current.scrollIntoView({
                    block: "center"
                })
                return
            } else {
                validateDataRef.current.className = birthDate + ' hidden';
            }    
            form.dateBirth = birthDate
        }

        if (requiredSizes) {
            const sizeTopForm = e.target?.sizeTop?.value || sizeTop;
            const sizeBottomForm = e.target?.sizeBottom?.value || sizeBottom;
            if (sizeTopForm === "Selecciona una talla" || sizeBottomForm === "Selecciona una talla") {
                sizesRef.current.className = "my-5 col-span-6 sm:col-span-6 lg:col-span-6 text-center";
                sizesRef.current.scrollIntoView({
                    block: "center"
                })
                return
            } else {
                sizesRef.current.className = "my-5 col-span-6 sm:col-span-6 lg:col-span-6 text-center hidden";
            }
            form.sizeTop = sizeTopForm
            form.sizeBottom = sizeBottomForm
        }
        onSubmit(form, isUpdate)
    }

    const onChangeFile = async (e) => {
        e.preventDefault()
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        const imageName = e.target.value
        
        if (e.target.files && e.target.files[0]) {
            const ext = getExtensionFile(e.target.value);
            if(!e.target.value) return;
            if(!['jpeg', 'png', 'jpg', 'svg'].includes(ext)) {
                const {title, description } = ErrorMessages.imageFormat;
                messageError(title, description);
                e.target.value = null;
                e.target.idBase64 = null;
                return;
            }
            const idBase64 = await getBase64(e.target.files[0]);

            const [file] = e.target.files;
            const objectUrl = URL.createObjectURL(file);
            setForm({
                ...form,
                [e.target.name]: imageName,
                idExt: ext,
                idBase64 : idBase64,
                idUrl : objectUrl
            })
        }
    }

    const getCategory = (categoryUuid) => {
        return categories.find(({uuid}) => uuid === categoryUuid);
    }

    return (  
        form && account.categoryUuid &&
        <div className="mt-10 md:mt-5 md:col-span-2">
            <form onSubmit={onSubmitButton}>
                <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                        <h3 className="text-3xl">
                            〉Información del equipo <hr />
                        </h3>
                        <div className="grid grid-cols-6 gap-6 mt-4 mb-14">
                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                <label class="block text-title" for="inline-full-name">
                                    Nombre de equipo
                                </label>
                                <p class="block tracking-wide text-gray-500 text-md font-bold mt-4" for="grid-first-name">
                                    <code>{account.teamName}</code>
                                </p>
                            </div>

                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                <label class="block text-title" for="inline-full-name">
                                    Categoría
                                </label>
                                <p class="block tracking-wide text-gray-500 text-md font-bold mt-4" for="grid-first-name">
                                    <code>{ getCategory(account.categoryUuid).name }</code>
                                </p>
                            </div>
                        </div>

                        <h3 className="text-3xl text-bold">
                            〉Información personal <hr />
                        </h3>
                        <div className="grid grid-cols-6 gap-6 mt-4 mb-10">
                            <Input
                                divClassName="col-span-6 sm:col-span-6 lg:col-span-2"
                                label="Nombre(s)"
                                placeholder="Nombre(s)"
                                name="name"
                                value={name}
                                onChange={onInputChange}
                                required="required"
                                pattern={validateName}
                            />

                            <Input
                                divClassName="col-span-6 sm:col-span-3 lg:col-span-2"
                                label="Apellido Paterno"
                                placeholder="Apellido Paterno"
                                name="lastName"
                                type="text"
                                value={lastName}
                                onChange={onInputChange}
                                required="required"
                                pattern={validateName}
                            />

                            <Input
                                divClassName="col-span-6 sm:col-span-3 lg:col-span-2"
                                label="Apellido Materno"
                                placeholder="Apellido Materno"
                                name="secondLastName"
                                type="text"
                                value={secondLastName}
                                onChange={onInputChange}
                                pattern={validateName}
                            />

                            <Input
                                divClassName="col-span-6 sm:col-span-6 lg:col-span-2"
                                label="Dí­a de nacimiento"
                                placeholder="00"
                                name="day"
                                value={day}
                                onChange={onInputChange}
                                pattern={validateDay}
                                title={titleDay}
                            />

                            <Input
                                divClassName="col-span-6 sm:col-span-3 lg:col-span-2"
                                label="Mes de nacimiento"
                                placeholder="00"
                                name="month"
                                type="text"
                                value={month}
                                onChange={onInputChange}
                                pattern={validateMonth}
                                title={titleMoth}
                            />

                            <Input
                                divClassName="col-span-6 sm:col-span-3 lg:col-span-2"
                                label="Año de nacimiento"
                                placeholder="2000"
                                name="year"
                                type="text"
                                value={year}
                                onChange={onInputChange}
                                pattern={validateYear}
                                title={titleYear}
                            />

                            <div ref={validateDataRef} className="my-5 col-span-6 sm:col-span-6 lg:col-span-6 text-center hidden">
                                <p className="text-primary text-red-500">Inserta una fecha válida.</p>
                            </div>

                            <div ref={validateBirthDataRef} className={birthDateClassname + ' hidden'}>
                                <p className={"text-primary text-red-500"}>{`Lo sentimos, debes ser mayor de ${ageLimit} años para ser entrenador.`}</p>
                            </div>

                            <Input
                                divClassName="col-span-6 sm:col-span-3 lg:col-span-2"
                                label="Teléfono"
                                placeholder="Teléfono"
                                name="phoneNumber"
                                type="text"
                                value={phoneNumber}
                                onChange={onInputChange}
                                minLength={10}
                                maxLength={15}
                                pattern={validateNumber}
                                title={titleNumber}
                            />
                        </div>

                        {requiredSizes && 
                            <>
                                <h3 className="text-3xl text-bold">
                                    〉Tallas <hr />
                                </h3>
                                <div className="grid grid-cols-6 gap-6 mt-4 mb-10">
                                    <div className="my-5 col-span-3 sm:col-span-3 lg:col-span-2">
                                        <label htmlFor="sizeCategoryTop" className="block text-title">
                                            Talla Top:
                                        </label>
                                        <Input
                                            id="sizeTop"
                                            name="sizeTop"
                                            type="select"
                                            onChange={onInputChange}
                                            options={sizes}
                                            value={sizeTop == null ? 'Selecciona una talla' : sizeTop}
                                        />
                                    </div>
                                    <div className="my-5 col-span-3 sm:col-span-3 lg:col-span-2">
                                        <label htmlFor="sizeCategoryBottom" className="block text-title">
                                            Talla Bottom:
                                        </label>
                                        <Input
                                            id="sizeBottom"
                                            name="sizeBottom"
                                            type="select"
                                            onChange={onInputChange}
                                            options={sizes}
                                            value={sizeBottom == null ? 'Selecciona una talla' : sizeBottom}
                                        />
                                    </div>

                                    <div ref={sizesRef} className="my-5 col-span-6 sm:col-span-6 lg:col-span-6 text-center hidden">
                                        <p className="text-primary text-red-500">Selecciona correctamente las tallas</p>
                                    </div>

                                    <div className="col-span-6 sm:col-span-6 lg:col-span-6 bg-gray-100 px-3 py-1 rounded-sm">
                                        <ul className="px-5 text-primary">
                                            <li>* Talla Top: Selecciona tu talla para la parte superior.</li>
                                            <li>* Talla Bottom: Selecciona tu talla para la parte inferior.</li>
                                        </ul>
                                    </div>
                                </div>
                            </>
                        }

                        <h3 className="text-3xl text-bold">
                            〉Documentos <hr />
                        </h3>
                        <div className="grid grid-cols-6 gap-6 mt-4 mb-10">
                            <div className="col-span-6 sm:col-span-6 lg:col-span-6 bg-gray-100 px-3 py-1 rounded-sm">
                                <p className="w-full text-primary">La identificación oficial puede ser uno de los siguientes documentos:</p>
                                <div className="px-5 text-primary">
                                    <li>INE</li>
                                    <li>Pasaporte</li>
                                    <li>Credencial de elector</li>
                                    <li>Licencia de conducir</li>
                                </div>
                            </div>
                            <Input
                                divClassName={`col-span-6 sm:col-span-3 ${(idUrl) ? ' lg:col-span-2' : ' lg:col-span-4'}`}
                                label="Identificación oficial"
                                name="idFile"
                                type="file"
                                onChange={onChangeFile}
                            />

                            {   idUrl &&  
                                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                    <ImageSimpleForm src={idUrl} alt="credential" text="Tú identificación"/>
                                </div>
                            }
                            
                            <Input
                                divClassName="col-span-6 sm:col-span-3 lg:col-span-3"
                                label="CURP/RFC"
                                placeholder="CURP/RFC"
                                name="identifier"
                                type="text"
                                value={identifier}
                                onChange={onInputChange}
                                pattern={validateCURPRFC}
                                title={titleCurpRfc}
                            />
                        </div>
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-center sm:text-left sm:px-6">
                            <button
                                type="submit"
                                className="btn-green"
                                onKeyDown={(e) => e.key === 'Enter' ? onSubmitButton : ''}
                            >
                                Guardar
                            </button>
                            <button
                                style={{ backgroundColor: getCupColor() }}
                                type="submit"
                                className="mx-10 my-10 btn-pink sm:my-0 md:my-0"
                                onKeyDown={(e) => e.key === 'Enter' ? onSubmitButton : ''}
                                onClick={onValidateAndSubmit}
                                >
                                Agregar Entrenador
                            </button>
                            
                    </div>
                </div>
            </form>
        </div>
    );
}
 
export default CoachForm;
