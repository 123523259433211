import { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Input from '../components/Input';
import { messageAlertCallback, messageError } from '../lib/AlertsUtils';
import { updatePayment, addPayment, addExtraPlayer } from '../lib/ApiEcyd';
import ApiErrorMapper from "../lib/ApiErrorMapper";
import { getApiServicesNames } from "../constants/ApiServices";

const ExtraPlayerSection = ({ account, cup }) => {
    const [showForm, setShowForm] = useState(false);
    const [extraPlayerCount, setExtraPlayerCount] = useState(0);
    const [enablePayment, setEnablePayment] = useState(false);
    const [payment, setPayment] = useState({
        loading : false,
        data : {},
        status: null,
        hasError : null
    });
    const [extraPayment, setExtraPayment] = useState({
        loading : false,
        data : {},
        status: null,
        hasError : null
    });
    const [totalAmount, setTotalAmount] = useState(0);
    const [requestAction, setRequestAction] = useState('add') // to determine if add or update a payment when error occurs
    const phone = cup.contact.phoneNumber;
    const services = getApiServicesNames();
    const calculateTotalAmount = () => {
        return extraPlayerCount * account.costPerPlayer;
    }
    const calculateExtraPlayersLeft = () => {
        const { maxPlayers } = cup.season.categories.find(({ uuid }) => uuid === account.categoryUuid) || 0;
        const { playersCount } = account || 0;
        return maxPlayers - playersCount;
    }
    const extraPlayersLeftCount = calculateExtraPlayersLeft();
    const extraPlayersOptions = new Array(extraPlayersLeftCount+1).fill().map((v,i)=>i);

    // handles extra player select
    const onChange = async(e) => {
        if (enablePayment) setEnablePayment(false);

        const count = Number(e.target.value);
        
        setExtraPlayerCount(count);
        setTotalAmount(count * account.costPerPlayer);

        if (!count) {
            setEnablePayment(false);
            return;
        }

        setTimeout(() => {
            setEnablePayment(true);
        }, 300);
    }

    // create order request to paypal
    const createExtraOrder = (data, actions) => {
        const description = String(process.env.REACT_APP_PAYPAL_EXTRA_PLAYER_ORDER_DESCRIPTION)
                            .replace('{{extraPlayerCount}}', extraPlayerCount);

        return actions.order.create({
            purchase_units: [
                {
                    custom_id: account.uuid,
                    description,
                    amount: {
                        currency_code: process.env.REACT_APP_CURRENCY,
                        value: calculateTotalAmount(),
                    },
                }
            ]
        });
    };

    // receive paypal response
    const onApprove = async (data, actions) => {
        const order = await actions.order.capture();
        handleApprove(order);
        return order;
    };

    // insert payment with information about the paypal payment transaction
    const handleApprove = async (order) => {
        if (order !== null &&
            order.purchase_units[0].payments.captures[0] !== null && 
            order.purchase_units[0].payments.captures[0].id !== null) {
                // set request action to 'update' in case the process failed, 
                // the created payment log below will be updated
                setRequestAction('update');
                
                // transaction id alert
                const transaction = order.purchase_units[0].payments.captures[0];

                // add success payment transaction to db
                const paymentPayload = {
                    "type": 3, // payment type id for extra players
                    "amount": calculateTotalAmount(),
                    "emailAccount": account.email,
                    "accountUuid": account.uuid,
                    "costPerPlayer": account.costPerPlayer,
                    "totalPlayersPaid": extraPlayerCount,
                    "isPaid": true,
                    "paidAt": transaction.create_time,
                    "transactionID": transaction.id,
                    "response": order,
                    "status": 1
                }
                const paymentAdded = await addPayment(paymentPayload, payment, setPayment);

                // adding extra player if addPayment have success
                if(!paymentAdded?.uuid) {
                    // alert error & send mail a isa y el #transaction id
                    setRequestAction('update');
                    const error = ApiErrorMapper(services.payment, paymentAdded.data.error.Code);
                    messageError("Error", error);
                    return;
                }
                await addExtraPlayer(paymentAdded.uuid, extraPayment, setExtraPayment);
                
                // reload page if everything goes well
                if (extraPayment.hasError === null) {
                    messageAlertCallback(
                        `Pago realizado con éxito \nID de Transacción : ${transaction.id}\n`,
                        `<strong>Por favor conserve este ID</strong>`,
                        'success'
                    ).then((result) => {
                        if (result.isConfirmed) window.location.reload();
                    })
                    setTimeout(() => {
                    }, 500);
                } else {
                    setRequestAction('update');
                    const error = ApiErrorMapper(services.payment, extraPayment.status);
                    messageError("Error", error);
                }
            } else {
                setRequestAction('add');
                // TODO: No se que regresa el actions.order.capture()
                messageError(
                    `Error al realizar el pago con PayPal`,
                    `No se pudo obtener el ID de la transacción, por favor comunícate al ${phone}`,
                );
            }

    };

    // send the error to API
    const saveErrorPayment = async (errorMessage) => {
        const paymentRequest = payment.data;
        paymentRequest.isPaid = false;
        paymentRequest.response = {};
        paymentRequest.errorMsg = errorMessage;
        paymentRequest.type = 3; // payment type id for extra players
        paymentRequest.amount = calculateTotalAmount();
        paymentRequest.emailAccount = account.email;
        paymentRequest.accountUuid = account.uuid;
        paymentRequest.costPerPlayer = account.costPerPlayer;
        
        if (requestAction === 'add') await addPayment(paymentRequest);
        else await updatePayment(paymentRequest, cup.data.contact.phoneNumber);
    }

    // handle paypal transaction error
    const onError = async (err) => {
        await saveErrorPayment(`There was an error trying to send payment to Paypal, [${err}]`);

        setEnablePayment(false);
        
        messageError(
            `Error al realizar el pago con PayPal`,
            `Hubo un error al realizar el pago, por favor comunícate al ${phone}`,
        );
    }

    // handle cancel paypal transaction 
    const onCancel= async (data) => {
        await saveErrorPayment("User has canceled the payment");

        setEnablePayment(false);

        messageError(
            `Pago Cancelado`,
            `Se ha cancelado el pago`,
        );
    }
    
    return (
        <>
            <div className="sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                {/* <Button
                    text="Pagar jugador extra"
                    message={`Puedes pagar hasta ${extraPlayersLeftCount} jugador(es) extra(s).`}
                    onclick={() => {setShowForm(!showForm)}}
                /> */}
                <button
                    id='addCoach'
                    type="button"
                    onClick={() => {setShowForm(!showForm)}}
                    className="px-4 py-2 mt-8 inline-flex items-center rounded-md border border-green-400 text-white shadow-sm bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white-500"
                >
                    {/* <PlusSmIconSolid className="h-5 w-5 m-auto p-auto" aria-hidden="true" /> */}
                    <span className='mr-1'>$ Pagar jugador extra</span>
                </button>
            </div>
            {
                showForm && 
                <div className='w-full md:inline-grid lg:inline-grid grid-cols-3 sm:flex mt-4'>
                    <div>
                        <label
                            htmlFor="playerCount"
                            className="truncate block text-sm font-medium text-gray-700 dark:text-gray-300">
                                # jugadores extra
                        </label>
                        <Input
                            name="playerCount"
                            placeholder={ 'Cantidad de jugadores' }
                            type="select"
                            options={extraPlayersOptions}
                            defaultValue=""
                            onChange={onChange}
                            divClassName="focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>

                    <div className="md:ml-6">
                        <label
                            htmlFor="costPerPlayer"
                            className="truncate block text-sm font-medium text-gray-700 dark:text-gray-300">
                                $ costo x jugador extra
                        </label>
                        <Input
                            name="costPerPlayer"
                            placeholder={ 'Precio por jugador' }
                            type="text"
                            value={`$${Number(account.costPerPlayer).toFixed(2)}`}
                            disabled={true}
                            divClassName="cursor-not-allowed focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="md:ml-6">
                        <label
                            htmlFor="totalAmount"
                            className="truncate block text-sm font-medium text-gray-700 dark:text-gray-300">
                                $ total a pagar
                        </label>
                        <Input
                            name="totalAmount"
                            placeholder={ 'Total a pagar' }
                            type="text"
                            value={`$${Number(totalAmount).toFixed(2)}`}
                            disabled={true}
                            divClassName="cursor-not-allowed focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>
                </div>
            }

            {(enablePayment && totalAmount > 0) && 
                <PayPalScriptProvider options={{
                    "client-id": cup?.paypalAccount,
                    "currency": process.env.REACT_APP_CURRENCY
                }}> 
                    <PayPalButtons 
                        className="text-center mt-8"
                        style={{ layout: "horizontal", color:"blue", shape:'pill', label:'pay'}}
                        createOrder={(data, actions) => createExtraOrder(data, actions)}
                        onApprove={(data, actions) => onApprove(data, actions)}
                        onError={(err) => onError(err)}
                        onCancel={(data) => onCancel(data)}
                    />
                </PayPalScriptProvider>
            }
        </>
    );
}
 
export default ExtraPlayerSection;