import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import AppRouter from './routers/AppRouter';
import DetailsProvider from "./context/details-context";
import CupProvider from "./context/cup-context";


const EcydFront = () => {
  return ( 
    //<React.StrictMode>
    <DetailsProvider>
      <CupProvider>
        <Router>
          <AppRouter/>
        </Router>
      </CupProvider>
    </DetailsProvider>
    //</React.StrictMode>
  );
}
 
export default EcydFront;