export const ErrorMessages = {
    imageFormat: {
        title: "Error Formato de Imagen", 
        description: "Solo se aceptan formato de Imágenes: 'jpeg', 'jpg', 'png', 'svg'",
    },
    noAcceptPdf: {
        title: "No se acepta formato PDF", 
        description: "Solo se aceptan formato de Imágenes: 'jpeg', 'jpg', 'png', 'svg'",
    },
    pdfFormat: {
        title: "Error en formato de documento", 
        description: "Solo se aceptan documentos en formato: 'pdf'",
    },
    fileSize: {
        title: "Error en tamaño de archivo", 
        description: "El tamaño del archivo debe ser menor a 6Mb",
    },
    passportFormat: {
        title: "Error en formato de documento", 
        description: "Solo se aceptan documentos en formato: 'jpeg', 'jpg', 'png', 'svg'",
    },
}
