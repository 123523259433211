
const SponsorSection = ({sponsors=[], titleStyle=''}) => {

    const className = (sponsors.length < 2) ?
                          "grid grid-cols-1 gap-8 md:grid-cols-1 lg:grid-cols-1"
                        : (sponsors.length < 3) ?
                          "grid grid-cols-1 gap-8 md:grid-cols-4 lg:grid-cols-2"
                        : (sponsors.length < 4) ?
                          "grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-3"
                        : "grid grid-cols-2 gap-8 md:grid-cols-4 lg:grid-cols-4"

    return (  
        <>
            <div className="relative py-10">
                <h2 className={titleStyle}>
                    Patrocinadores
                </h2>
                <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-primary">
                    Agradecemos el apoyo de nuestros patrocinadores quienes hacen posible la realización de las Copas
                </p>
            </div>
            <div className="mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <div className={className}>
                {sponsors.map((sponsor) => (
                    <div key={sponsor.uuid} className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 lg:transition lg:ease-in-out lg:delay-150 lg:hover:-translate-y-1 lg:hover:scale-110 lg:duration-300">
                        <a href={sponsor.siteUrl} rel='noreferrer' target='_blank'>
                            <img className="h-14 sm:h-24 w-auto" src={sponsor.imageUrl} alt={sponsor.name} />
                        </a>
                    </div>
                ))}
                </div>
            </div>
        </>
    );
}
 
export default SponsorSection;