import React from 'react';
import { createRoot } from 'react-dom/client';

import './index.css'
import EcydFrontApp from './ecyd_front';

const root = createRoot(document.getElementById("root"));
root.render(<EcydFrontApp />);


