import { useParams } from 'react-router-dom';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; 
import { Link } from 'react-router-dom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import renderError from '../constants/DocumentErrorRender';
import landing from "../resources/landing.json";

const DocumentViewer = ({ cup }) => {
  const { cupPermalink, documentPermalink } = useParams();
  const pdfUrl = cup.data.files?.[documentPermalink]
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  
  if(!pdfUrl) return (
    <div className='flex h-96 justify-center items-center flex-col gap-y-5'>
      <div className="flex-shrink-0 flex justify-center">
        <span className="sr-only">ecyd-logo</span>
          <img
            className="h-12 w-auto"
            src={landing.logoUrl}
            alt=""
          />
        </div>
      <h1 className="mt-2 text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl sm:tracking-tight">Proximamente...</h1>
      <Link to={`/${cupPermalink}`} className="text-base font-medium text-red-600 hover:text-red-500">
        Regresar a Copa<span aria-hidden="true"> &rarr;</span>
      </Link>
    </div>
  )

  return (
    <div className='pdf-container' style={{ height: '60rem'}} >
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
        <Viewer 
          fileUrl={pdfUrl}
          renderError={renderError} 
          plugins={[defaultLayoutPluginInstance]} />
      </Worker>
    </div>
  )
}

export default DocumentViewer;