import { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const GallerySection = ({ gallery = [] }) => {
    const [showModal, setShowModal] = useState(false);
    const [expadedImage, setExpandedImage] = useState(null);
    const expandImage = (i) => {
        setExpandedImage(gallery[i]);
        setShowModal(true);
    }

    const onCloseModal = () => {
        setExpandedImage(null);
        setShowModal(false);
    }

    return (  
        <>
            <div className="relative py-10">
                <h2 className="text-title-primary text-center leading-8 tracking-tight sm:text-4xl sm:tracking-tight">
                    Galería
                </h2>
            </div>
            <ul className="mx-5 pb-14 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
            {gallery.map((file, i) => (
                <li key={file.title} className="relative">
                    <div
                        className="group block w-full aspect-w-6 aspect-h-6 rounded-lg bg-gray-100 overflow-hidden"
                        onClick={() => { expandImage(i) }}
                    >
                        <img src={file.url} alt={file.title} className="w-52 h-52 object-cover pointer-events-none group-hover:opacity-75" />
                        <button type="button" className="absolute inset-0 focus:outline-none">
                        <span className="sr-only">View details for {file.title}</span>
                        </button>
                    </div>
                </li>
            ))}
            </ul>

            {
                showModal &&
                <Transition.Root show={showModal} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={onCloseModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed z-10 inset-0 overflow-y-auto">
                            <div className="flex items-end sm:justify-center items-center min-h-full p-4 text-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel
                                        className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                                        {
                                            expadedImage &&
                                                <div
                                                    className="group block w-full aspect-w-6 aspect-h-6 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-gray-500 overflow-hidden"
                                                    onClick={() => { setShowModal(false) }}
                                                >
                                                    <img src={expadedImage.url} alt={expadedImage.title} className="object-cover pointer-events-none group-hover:opacity-75" />
                                                    <button type="button" className="absolute inset-0 focus:outline-none">
                                                    <span className="sr-only">View details for {expadedImage.title}</span>
                                                    </button>
                                                </div>
                                        }
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            }
        </>
    );
}
 
export default GallerySection;