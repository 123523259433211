import { Link } from 'react-router-dom';
import landing from "../resources/landing.json";

import { ErrorMessages, CONTACT_INFO } from "../constants/ApiErrorMessages";

const linktTo = {
    coach: "Mi Cuenta",
    player: "Mi Cuenta",
    document: "Copa"
}
const ErrorPage = ({ error = {}, type = 'cup', link }) => {
    const code  = error?.response?.data?.error?.Code || error?.response?.status || 404;

    return (  
        <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
            <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex-shrink-0 flex justify-center">
                    <a href="/" className="inline-flex">
                        <span className="sr-only">ecyd-logo</span>
                        <img
                            className="h-12 w-auto"
                            src={landing.logoUrl}
                            alt=""
                        />
                    </a>
                </div>
                <div className="py-16">
                    <div className="text-center">
                        <p className="text-base font-semibold text-red-600">{code}</p>
                        <h1 className="mt-2 text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl sm:tracking-tight">
                            {ErrorMessages[type][code] || "Lo sentimos, esta página no existe."}
                        </h1>
                        <p className="mt-2 text-base text-gray-500">{CONTACT_INFO}</p>
                        <div className="mt-6">
                            <Link to={link || "/"} className="text-base font-medium text-red-600 hover:text-red-500">
                                Regresar a {link ? linktTo[type] : "Inicio"}<span aria-hidden="true"> &rarr;</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
 
export default ErrorPage;