import { useState } from "react";
import { ExclamationCircleIcon } from '@heroicons/react/solid'

const Input = ({
    divClassName='col-span-6',
    label=null,
    id=null,
    type="text",
    name='name',
    placeholder,
    initialValue = "",
    autoComplete,
    className='block w-full mt-1 pr-10 rounded-md shadow-sm border-gray-300 text-primary focus:ring-gray-500 focus:border-gray-500 sm:text-sm ',
    classNameError='block w-full mt-1 pr-10 rounded-md shadow-sm border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500 sm:text-sm placeholder-red-300 focus:outline-none',
    errorMessage=null,
    message=null,
    min=null,
    max=null,
    maxLength=null,
    disabled=null,
    required=false,
    options = [],
    // By default in the Select we will request the "name" as value but if it requires another attribute 
    // of the entity it can occupy attributeName
    // For example, in the cups if you require "displayName" use attributeName="displayName"
    validation = ".*",
    selectDefaultValue=null,
    setParentState = (e) => e,
    isEmail = false,
}) => {
    const [value, setValue] = useState(initialValue || window.sessionStorage.getItem(id) || "");
    const [classNameInput, setClassNameInput] = useState(errorMessage ? classNameError : className);
    const regex = RegExp(validation);

    const onChange = (e) => {
        setParentState(e)
        e.preventDefault();
        setValue(isEmail ? e.target.value.toLowerCase() : e.target.value);
        setClassNameInput( regex.test(e.target.value) ? className : classNameError)
        if(type !== "file") window.sessionStorage.setItem(id, e.target.value);
    }

    return ( 
        <div className={divClassName}>
            {label && 
                <label htmlFor={id || name} className="block text-title">
                    {label}
                </label>
            }
            <div className="relative">
                {
                    type === 'select' ?
                        <select
                            id={id || name}
                            name={name}
                            autoComplete={autoComplete || name}
                            value={value}
                            className={classNameInput}
                            aria-invalid={errorMessage ? true : false}
                            aria-describedby={name + '-message'}
                            onChange={onChange}
                            disabled={disabled}
                            defaultValue={selectDefaultValue}
                        >
                        {options.map((option) => (
                            <option key={option}>{option}</option>
                        ))}
                        </select>
                    : 
                        <input
                            type={type}
                            id={id || name}
                            name={name}
                            autoComplete={autoComplete || name}
                            className={classNameInput}
                            placeholder={placeholder}
                            value={value}
                            aria-invalid={errorMessage ? true : false}
                            aria-describedby={name + '-message'}
                            min={min}
                            max={max}
                            maxLength={maxLength}
                            onChange={onChange}
                            disabled={disabled}
                            required={required}
                            pattern={validation}
                        />
                }
                { errorMessage &&
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>
                }
            </div>
            {
                (errorMessage || message) &&
                <p className={'mt-2 text-sm text-red-600 text-justify'} id={name + '-message'}>
                    *{errorMessage || message}
                </p>
            }
        </div>
     );
}
 
export default Input;
