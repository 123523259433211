import { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../components/inputForm';
import Navbar from "../components/NavbarEcyd";
import Footer from "../containers/FooterEcyd";
import { Attributes } from "../constants/CupData";
import { useCup } from "../context/cup-context";
import { resetPassword } from "../lib/ApiEcyd";
import { ErrorMessages } from "../constants/ApiErrorMessages";
import landing from '../resources/landing.json';
import { 
    validatePassword as passwordPattern
} from '../utils/validations';
import { passwordErrorMessage } from "../utils/validations";

const ResetPassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null) 

    const [errorPasswordConfirm, setErrorPasswordConfirm] = useState(false);
    const [errorPassword, setErrorPassword] =  useState(false);

    const { cupPermalink, changePasswordToken } = useParams();

    const { cup, getCupByPermalink } = useCup();

    useEffect(() => {
        getCupByPermalink(cupPermalink);
    }, [cupPermalink, getCupByPermalink])

    const attributes = Attributes(cupPermalink);

    const onSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData()

        const password = e.target.password.value;
        const confirmPassword = e.target.confirmPassword.value;

        const validPassword = passwordErrorMessage(password, true);
        if(validPassword) {
            setErrorPassword(validPassword);
            setErrorPasswordConfirm(null)
            return;
        }
        setErrorPassword(null)
        if(password !== confirmPassword) {
            setErrorPasswordConfirm(`El campo "Confirmar contraseña" no coincide con el campo "Contraseña"`);
            return;
        }
        setErrorPasswordConfirm(null)

        formData.append("password", password);
        formData.append("confirmPassword", confirmPassword);

        setLoading(true);
        const response = await resetPassword(formData, changePasswordToken, cup?.data?.contact?.phoneNumber);
        setLoading(false);
        if(response?.statusText === "No Content"|| response?.status === 201 || response?.status === true) {
            navigate(`/${cupPermalink}`)
        } else {
            setError(response?.data?.error?.Code || response?.status || 404 );
        }
    }
    
    return (
        <div className="max-w-5xl mx-auto px-4 sm:px-6 min-h-screen flex flex-col justify-between">            
            <Navbar logoUrl={cup.data.imageUrl || attributes.cupImgUrl} menuSections={attributes.menu}/>
            <main className="flex justify-center items-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                <form onSubmit={onSubmit}>
                    <div className="flex flex-col gap-y-3 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                        <h3 className="text-lg leading-6 font-bold text-pink-700 text-center pb-4">
                            Cambiar Contraseña
                        </h3>
                        <>
                            <p>Escribe la dirección de correo electrónico asociado a tu cuenta.</p>
                                <Input
                                    name="password"
                                    placeholder={ landing.accessSign.form.placeholders.password }
                                    errorMessage={errorPassword}
                                    type="password"
                                    disabled={loading}
                                    pattern={passwordPattern.pattern()}
                                />
                                <Input
                                    name="confirmPassword"
                                    placeholder={ landing.accessSign.form.placeholders.confirmPassword }
                                    type="password"
                                    disabled={loading}
                                    errorMessage={errorPasswordConfirm}
                                    pattern={passwordPattern.pattern()}
                                />
                                {error && <p className="text-red-600">{ErrorMessages.resetPassword[error]}</p>}
                            <div className="mt-3 text-center">
                                <button
                                    className="btn-pink position: relative"
                                    type="submit"
                                    disabled={loading}
                                    >
                                    Continuar
                                    { loading && <div className="loader" /> }
                                </button>
                            </div>
                        </>
                    </div>
                </form>
            </main>
            <Footer logoUrl={cup.data.imageUrl || attributes.cupImgUrl } menuSections={attributes.menu} />
        </div>
    );
}
 
export default ResetPassword;