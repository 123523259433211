import { useRef } from "react";
const usePointInvalidMessage = (className="") => {
    const ref = useRef();
    const toggle = (val) => {
        ref.current.className = `${className} ${val && "hidden"}`;
        if(!val) {
            ref.current.scrollIntoView({
                block: "center"
            })
        }
    }
    return [ref, (val) => toggle(val)]
}
export default usePointInvalidMessage;