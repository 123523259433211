const NewsSection = ({news=[], title='', description=''}) => {

    return (
        <> 
            <div className="relative py-10">
                <h2 className="text-title-primary text-center leading-8 tracking-tight sm:text-4xl sm:tracking-tight">
                    {title}
                </h2>
                <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-primary">
                    {description}  
                </p>
            </div>
            <div className="hidden w-full space-x-4 items-end">
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-alt-circle-left" className="w-7 h-7" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M8 256c0 137 111 248 248 248s248-111 248-248S393 8 256 8 8 119 8 256zm448 0c0 110.5-89.5 200-200 200S56 366.5 56 256 145.5 56 256 56s200 89.5 200 200zm-72-20v40c0 6.6-5.4 12-12 12H256v67c0 10.7-12.9 16-20.5 8.5l-99-99c-4.7-4.7-4.7-12.3 0-17l99-99c7.6-7.6 20.5-2.2 20.5 8.5v67h116c6.6 0 12 5.4 12 12z"></path>
                </svg>
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-alt-circle-right" className="w-7 h-7" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256zm72 20v-40c0-6.6 5.4-12 12-12h116v-67c0-10.7 12.9-16 20.5-8.5l99 99c4.7 4.7 4.7 12.3 0 17l-99 99c-7.6 7.6-20.5 2.2-20.5-8.5v-67H140c-6.6 0-12-5.4-12-12z"></path>
                </svg>
            </div>
            <div className="my-10 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                {news.map((element) => (
                    <div key={element.uuid} className="flex flex-col rounded-lg shadow-lg overflow-hidden lg:col-span-1 lg:transition lg:ease-in-out lg:delay-150 lg:hover:-translate-y-1 lg:hover:scale-110 lg:duration-300">
                        <div className="flex-shrink-0">
                            <img className="h-48 w-full object-cover" src={element.imageUrl} alt={element.name} />
                        </div>
                        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                            <div className="flex-1">
                                <a href={element.href} className="block mt-2" rel='noreferrer' target='_blank'>
                                    <p className="text-xl font-semibold text-gray-900">{element.title}</p>
                                    <p className="mt-3 text-base text-gray-500">{element.description.substring(0, 150) + ' ...'}</p>
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </>
    );
}
 
export default NewsSection;