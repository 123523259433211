export const Numbers = [
    {
        "key" : '0',
        "value" : "Número de Jugadores"
    },
    {
        "key" : '10',
        "value" : "10"
    },
    {
        "key" : '11',
        "value" : "11"
    },
    {
        "key" : '12',
        "value" : "12"
    },
    {
        "key" : '13',
        "value" : "13"
    },
    {
        "key" : '14',
        "value" : "14"
    },
    {
        "key" : '15',
        "value" : "15"
    },
    {
        "key" : '16',
        "value" : "16"
    },
    {
        "key" : '17',
        "value" : "17"
    },
    {
        "key" : '18',
        "value" : "18"
    },
    {
        "key" : '19',
        "value" : "19"
    },
    {
        "key" : '20',
        "value" : "20"
    },
    {
        "key" : '21',
        "value" : "21"
    },
    {
        "key" : '22',
        "value" : "22"
    },
    {
        "key" : '23',
        "value" : "23"
    },
    {
        "key" : '24',
        "value" : "24"
    },
    {
        "key" : '25',
        "value" : "25"
    },
]

export const CurrentYear = "2024"
