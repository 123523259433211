const WhoAreWeSection = () => {
  const logo = `${window.location.origin}/logo_pokal.png`;
  return (
    <div className="relative pb-5 pt-10 static">
      {/* <img className="absolute opacity-25 top-0" src="https://static.vecteezy.com/system/resources/previews/001/204/025/original/soccer-png.png" alt="about" /> */}
      <div className="flex items-center mb-4">
        <div className="w-16 h-16 mr-4">
          <img src={logo} alt={"logo_pokal"}/>
        </div>
        <h2 className="text-title-primary leading-8 tracking-tight sm:text-4xl sm:tracking-tight">
          Sobre Pokal
        </h2>
      </div>

      <ul className="list-disc leading-10 px-6 text-justify">
        <li>
          Nos dedicamos al mundo del{" "}
          <strong className="text-amber-800">futbol desde 1999</strong>.
        </li>
        <li>
          Creamos{" "}
          <a
            href="https://www.mediotiempo.com/"
            className="text-amber-800 hover:text-amber-700 text-underline font-bold"
          >
            mediotiempo.com
          </a>{" "}
          (2000-2010) y{" "}
          <a
            href="https://juanfutbol.com/"
            className="text-amber-800 hover:text-amber-700 font-bold"
          >
            juanfutbol.com
          </a>{" "}
          (2014-2022).
        </li>
        <li>
          Desde 2016 operamos{" "}
          <a
            href="https://tikitaka.mx/"
            className="text-amber-800 hover:text-amber-700 font-bold"
          >
            tikitaka
          </a>
          , agencia líder de sports marketing trabajando con 
          las organizaciones deportivas más relevantes del mundo.
        </li>
        <li>
          A través de nuestra filial{" "}
          <strong className="text-amber-800">pokal</strong>, organizamos eventos
          deportivos y desde desde 2019 somos responsables de la{" "}
          <strong className="text-amber-800">
            organización y comercialización de las Copas RC
          </strong>
          , torneos de futbol para niños y niñas en la Ciudad de México, 
          que tienen más de 20 años llevándose a cabo con gran éxito.
        </li>
        <li>
          Nuestro objetivo es que las Copas sigan{" "}
          <strong className="text-amber-800">
            creciendo y fortaleciéndose
          </strong>{" "}
          para ofrecer una mejor experiencia a jugadores, 
          padres de familia, asistentes y patrocinadores.
        </li>
      </ul>
    </div>
  );
};

export default WhoAreWeSection;
