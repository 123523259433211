export const enrollmentDatesValidation = (season) => {
  if (season) {
    if (season.isActive) {
      const inscriptionStartDate = Date.parse(season.enrollmentStartedAt);
      const inscriptionEndDate = Date.parse(season.extensionEnrollmentAt);
      const today = Date.parse(new Date());
      return inscriptionStartDate <= today && today <= inscriptionEndDate;
    }
  }
  return false;
};
