import { useEffect } from 'react';
import SponsorSection from "../containers/SponsorSection";
import Navbar from "../components/NavbarEcyd";
import HeaderSign from "../components/AccessSign";
import { Attributes } from "../constants/CupData";
import DocumentViewer from "../components/DocumentViewer";
import { useParams } from 'react-router-dom';
import Footer from '../containers/FooterEcyd';
import { useCup } from "../context/cup-context";
import ErrorPage from './ErrorPage';
import { enrollmentDatesValidation } from '../lib/helpers';

const DocumentPage = () => {
    const {cupPermalink} = useParams();
    const { cup, getCupByPermalink } = useCup();

    useEffect(() => {
        getCupByPermalink(cupPermalink);
    }, [cupPermalink, getCupByPermalink])

    const attributes = Attributes(cupPermalink);

    if(cup.hasError) return <ErrorPage error={cup.hasError} type="cup"/>

    return (
        !cup.loading &&
        <div className="max-w-5xl mx-auto px-4 sm:px-6">
            <HeaderSign
              cupUuid={cup.data?.uuid}
              seasonUuid={cup.data?.season?.uuid || ""}
              categories={cup.data?.season?.categories}
              enrollmentDates={enrollmentDatesValidation(cup?.data?.season)}
            />
            <Navbar
                logoUrl={cup.data.imageUrl || attributes.cupImgUrl}
                menuSections={attributes.menu}
            />
            <DocumentViewer cup={cup}/>
            <section id="sponsors" className="bg-blue-secondary">
                <SponsorSection
                    sponsors={cup.data?.sponsors || []}
                    titleStyle={"text-title-primary-black text-center leading-8 tracking-tight sm:text-4xl sm:tracking-tight"}
                />
            </section>
            <Footer
                logoUrl={cup.data.imageUrl || attributes.cupImgUrl}
                menuSections={attributes.menu}
                termsAndConditionsUrl={cup.data?.files?.termsAndConditionsDocUrl}
                showNavbar={true}
            />
        </div>
    );
}

export default DocumentPage;