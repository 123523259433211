import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { useState } from 'react';

const Input = ({
    divClassName='col-span-6',
    label,
    id=null,
    name='name',
    type='text',
    placeholder,
    message="",
    value,
    autoComplete,
    className,
    classNameError,
    errorMessage,
    min,
    max,
    maxLength,
    minLength,
    disabled,
    required,
    defaultValue,
    pattern = ".*",
    title,
    options = [],
    // By default in the Select we will request the "name" as value but if it requires another attribute 
    // of the entity it can occupy attributeName
    // For example, in the cups if you require "displayName" use attributeName="displayName"
    attributeName,
    onChange,
    onKeyUp,
    onFocus,
    isEmail = false,
}) => {
    const defaultClasses = 'block placeholder:italic placeholder:text-gray-400 w-full mt-1 pr-10 rounded-md shadow-sm border-gray-300 text-primary focus:ring-gray-500 focus:border-gray-500 sm:text-sm';
    const defaultErrorClasses = 'block placeholder:italic placeholder:text-gray-400 w-full mt-1 pr-10 rounded-md shadow-sm border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500 sm:text-sm placeholder-red-300 focus:outline-none';
    const classes = `${defaultClasses} ${className}`;
    const errorClasses = `${defaultErrorClasses} ${classNameError}`;

    const [classNameInput, setClassNameInput] = useState(errorMessage ? errorClasses : classes);

    const regex = RegExp(pattern);

    const onChangeInput = (e) => {
        e.preventDefault();
        onChange(e);
        setClassNameInput( regex.test(e.target.value) ? classes : errorClasses);
    }

    if (isEmail) {
        value = value?.toLowerCase();
    }

    return ( 
        <div className={divClassName}>
            {label && 
                <label htmlFor={id || name} className="block text-title">
                    {label}
                </label>
            }
            <div className="relative">
                {
                    type === 'select' ?

                    <select
                        id={id || name}
                        name={name}
                        autoComplete={autoComplete || name}
                        value={value}
                        className={classNameInput}
                        aria-invalid={errorMessage ? true : false}
                        aria-describedby={name + '-message'}
                        onChange={onChangeInput}
                        disabled={disabled}
                        required={required}
                        onFocus={onFocus}
                    >
                    {
                        typeof options[0] === 'object'
                        ?
                            options.map((option) => (
                                <option key={option.uuid} value={option.uuid}>{attributeName ? option[attributeName] : option.name}</option>
                            ))
                        : 
                            options.map((option) => (
                                <option key={option} value={option}>{option}</option>
                            ))
                    }
                    </select>
                    : 
                    <input
                        type={type}
                        id={id || name}
                        name={name}
                        autoComplete={autoComplete || name}
                        className={classNameInput}
                        placeholder={placeholder}
                        value={value}
                        aria-invalid={errorMessage ? true : false}
                        aria-describedby={name + '-message'}
                        min={min}
                        max={max}
                        minLength={minLength}
                        maxLength={maxLength}
                        onChange={onChangeInput}
                        disabled={disabled}
                        required={required}
                        onKeyUp={onKeyUp}
                        defaultValue={defaultValue}
                        pattern={pattern}
                        title={title}
                    />
                }
                {/* {
                    message && <div className="mt-2 text-sm text-red-600">*{message}</div>
                } */}
                { errorMessage &&
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>
                }
            </div>
            {
                (errorMessage || message) &&
                <p className={'mt-2 text-sm text-red-600 text-justify'} id={name + '-message'}>
                    *{errorMessage || message}
                </p>
            }
        </div>
     );
}
 
export default Input;
