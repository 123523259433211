
export const ErrorMessages = {
    402: false,
    404: true,
    418: true,
    500: true,
    502: false,
    509: true,
    516: false,
    535: true,
    536: true,
    543: false
}
