
export const getDayBetweenDates = (dateStart, dateEnd) => {
    //console.log(dateStart)
    const diff = dateEnd.getTime() - dateStart.getTime()
    //console.log(diff/(1000*60*60*24))
    return Math.floor(diff/(1000*60*60*24))
}

export const getYearsBetweenDates = (dateStart, dateEnd) => {
    //console.log(dateEnd)
    const diff = dateEnd.getTime() - dateStart.getTime()
    //console.log(diff/(1000*60*60*24))
    return Math.floor((diff/(1000*60*60*24))/365)
}