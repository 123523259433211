const NAME_REGEX = "^([^0-9]*)$";
const NUMBER_REFEX = "^([0-9]+)$";
const CURP_REGEX = "^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$";
const EMAIL_REGEX = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9-]+[.][A-Za-z.]{2,}$";
const DAY_REGEX = "^(0[1-9]|1[0-9]|2[0-9]|3[0-1])$";
const MONTH_REGEX = "^(0[1-9]|1[0-2])$";
const YEAR_REGEX = "^(19|20)\\d{2}$";
const CURP_RFC_REGEX = "^([A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1})|([A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z0-9]{3}))$";
const PHONE_REGEX = "^([0-9]{10,13})$";
const TEXT_RANGE_REGEX = (min = 1, max = '') => `^[a-zA-Z0-9áéíóú -]${ max ? `{${ min },${ max }}` : `{${ min },}`}$`;
const PASSWORD_REGEX = {
    lowerCase: "([a-z])",
    upperCase: "(?=.*[A-Z])",
    number: "(?=.*[0-9])",
    chars: "(?=.*[!@#$%^&*])",
    pattern: (min = 8) => `(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{${min},})`
};

export const validateName = NAME_REGEX;
export const validateNumber = NUMBER_REFEX;
export const validateCURP = CURP_REGEX;
export const validateCURPRFC = CURP_RFC_REGEX;
export const validateEmail = EMAIL_REGEX;
export const validateDay = DAY_REGEX;
export const validateMonth = MONTH_REGEX;
export const validateYear = YEAR_REGEX;
export const validatePassword = PASSWORD_REGEX;
export const validateTextRange = TEXT_RANGE_REGEX;
export const validatePhone = PHONE_REGEX;
export const titleCurp = 'revisa los dieciocho caracteres de tu CURP'
export const titleCurpRfc = 'revisa los dieciocho o trece caracteres de tu CURP o RFC con homoclave'
export const titleEmail = 'verifica el formato del email, ej: correo@gmail.com'
export const titleDay = 'escribe un día del mes 01-31'
export const titleMoth = 'escribe un mes del año 01-12'
export const titleYear = 'escribe un año válido de 4 dígitos'
export const titleNumber = 'escribe sólo números'

// validators
export const emailErrorMessage = (email, isInputTouched, fieldName = '"Correo eletrónico"') => {
    const errorMessage = (!email.length && isInputTouched)
                        ? `${fieldName} es requerido`
                        :(!RegExp(validateEmail).test(email) && isInputTouched)
                        ? `${fieldName} no cumple con el formato: abc@xyz.io`
                        : ``;
    return errorMessage;
}
export const phoneErrorMessage = (phone, isInputTouched, fieldName = '"Teléfono"') => {
    return  (!phone.length && isInputTouched)
            ? `El campo ${fieldName} es requerido` 
            : (!RegExp(validateNumber).test(phone) && isInputTouched)
            ? `El campo ${fieldName} debe ser sólo números`
            : (phone.length < 10 && isInputTouched) 
            ? `El ${fieldName} debe ser al menos de 10 caracteres`
            : (phone.length > 13 && isInputTouched)
            ? `El ${fieldName} debe ser máximo de 13 caracteres`
            : ``;
}
export const textErrorMessage = (teamName, isInputTouched, fieldName = '"Nombre del campo"', limit = 3) => {
    return (!teamName.length && isInputTouched)
            ? `El campo ${fieldName} es requerido`
            : (teamName.length < limit && isInputTouched)
            ? `El campo ${fieldName} debe ser al menos de ${limit} caracteres`
            : (!RegExp(validateTextRange(limit)).test(teamName) && isInputTouched)
            ? `El campo ${fieldName} solo acepta el caracter "-"`
            : ``;
}
export const categoryErrorMessage = (categoryUuid, isInputTouched, fieldName = '"Categorías"') => {
    return (!RegExp(/[a-zA-Z]/).test(categoryUuid) && isInputTouched)
            ? `El campo ${fieldName} es requerido`
            : ``;
}
export const passwordErrorMessage = (password, isInputTouched, fieldName = '"Constraseña"', limit = 8) => {
    return !password.length && isInputTouched
            ? `El campo ${fieldName} es requerido`
            :  (!RegExp(validatePassword.lowerCase).test(password) && isInputTouched)
            ? `El campo ${fieldName} debe tener al menos 1 letra minúscula`
            : (!RegExp(validatePassword.upperCase).test(password) && isInputTouched)
            ? `El campo ${fieldName} debe tener al menos 1 letra mayúscula`
            : (!RegExp(validatePassword.chars).test(password) && isInputTouched)
            ? `El campo ${fieldName} debe tener al menos 1 caracter especial (ej: !#$%)`
            : (!RegExp(validatePassword.number).test(password) && isInputTouched)
            ? `El campo ${fieldName} debe tener al menos 1 número`
            : password.length < limit && isInputTouched
            ? `El campo ${fieldName} debe ser mayor a ${limit} caracteres`
            : ``;
}

export const boletiaCodeErrorMessage = (boletiaCode, isInputTouched, fieldName = '"Código Boletia"') => {
    const errorMessage = (!boletiaCode.length && isInputTouched)
                        ? `${fieldName} es requerido`
                        : ``;
    return errorMessage;
}
