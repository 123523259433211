import React from "react";
import Navbar from "../components/NavbarEcyd";
import { useNavigate } from "react-router-dom";

import { CurrentYear } from "../constants/Numbers";
const Footer = ({
  termsAndConditionsUrl = null,
  logoUrl = "/",
  menuSections = [],
  showNavbar = false,
}) => {
  const navigate = useNavigate();

  const handlePrivacyClick = () => {
    navigate("/privacy");
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  
  return (
    <>
      {showNavbar && <Navbar logoUrl={logoUrl} menuSections={menuSections} />}
      <div className="pt-8 pb-5 md:flex md:items-center md:justify-between">
        <div className="flex space-x-6 md:order-2">
          {termsAndConditionsUrl && (
            <a
              href={termsAndConditionsUrl}
              className="text-gray-400 hover:text-gray-500"
              rel="noreferrer"
              target="_blank"
            >
              <span>Terminos y condiciones</span>
            </a>
          )}
          {/* Link to "Aviso de Privacidad" */}
          <span
            className="text-black cursor-pointer"
            onClick={handlePrivacyClick}
          >
            Aviso de Privacidad
          </span>
        </div>
        <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
          &#174; {CurrentYear} ECYD All rights reserved{" "}
          <span className="text-gray-600 md:mx-0 lg:mx-0 mx-10">
            {" "}
            @Powered By Pokal{" "}
          </span>{" "}
        </p>
      </div>
    </>
  );
};

export default Footer;
