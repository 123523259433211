import { PlusSmIcon as PlusSmIconSolid, InformationCircleIcon, ClipboardCopyIcon } from '@heroicons/react/solid'
import { useNavigate } from 'react-router-dom';
import StackedList from '../components/StackedList';
import ExtraPlayerSection from '../containers/ExtraPlayerSection';
import ApiErrorMapper from "../lib/ApiErrorMapper";
import { copyOldPlayersInNewSeason } from "../lib/ApiEcyd";
import { getApiServicesNames } from "../constants/ApiServices";
import { messageError, messageAlertCallback } from "../lib/AlertsUtils";

const ManageTeamSection = ({cup, account, onDeletePlayer=null}) => {
    const redirect = useNavigate();
    const canAddCoach = account.coach ? false : true;
    const availablePlayers = account.playersCount - (account.players ? account.players.length : 0);
    const services = getApiServicesNames();
    const addPlayersFromLastSeason = async () => {
      const response = await copyOldPlayersInNewSeason();
        if (response.status !== 201) {
          const error = ApiErrorMapper(
            services.account,
            response.data.error.Code
          );
          messageError("Error", error);
          return;
        }
    }
    const copyPlayers = () => {
      const info = account.playersCount < account.lastPlayersCount ? `<small>*Actualmente solo puedes tener ${account.playersCount} jugadores, por tanto se copiaran los primeros ${account.playersCount}  de los ${account.lastPlayersCount} jugadores por orden de creación.<small>` : "";
      messageAlertCallback(
        `¿Quieres copiar tu entrenador/jugadores de la temporada pasada?\n`,
        `<p>No. de jugadores de la temporada pasada: <strong>${account.lastPlayersCount}</strong></p> ${info}`,
        "info",
        true,
        "Sí",
        "No"
      ).then(async (result) => {
        if (result.isConfirmed) {
          await addPlayersFromLastSeason();
          window.location.reload();
        }
      });
    }
    const accountHasPlayers = ((account.players === undefined || (account.players && account.players.length === 0)) && account.lastPlayersCount > 0);

    return (  
        <>
            <div className="relative py-5 px-2">
                <h2 className="text-title-subtitle text-left leading-8 tracking-tight sm:text-1xl sm:tracking-tight">
                    Administrar equipo
                </h2>
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    <div className="sm:flex items-center">
                        {
                            availablePlayers > 0 &&
                            <>
                                {/* <button
                                    id='addPlayer'
                                    type="button"
                                    className="inline-flex items-center border border-black rounded-full shadow-sm text-black bg-white-600 hover:bg-white-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white-500"
                                >
                                    <Link to={`/${cup.permalink}/player/new/#`} >
                                        <PlusSmIconSolid className="h-5 w-5" aria-hidden="true" />
                                    </Link>
                                </button>
                                <label htmlFor='addPlayer' className="ml-3 block text-sm font-medium text-gray-700">
                                    <Link to={`/${cup.permalink}/player/new/#`}> 
                                        Agregar jugador
                                    </Link>
                                </label> */}
                                <button
                                    id='addPlayer'
                                    type="button"
                                    onClick={() => { redirect(`/${cup.permalink}/player/new/#`) }}
                                    className="inline-flex justify-center sm:justify-start w-full sm:w-auto px-4 py-2 items-center border border-green-400 text-white rounded-md shadow-sm bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white-500"
                                >
                                    <PlusSmIconSolid className="h-5 w-5 sm:m-auto sm:p-auto" aria-hidden="true" />
                                    <span className='mr-1'>Agregar jugador</span>
                                </button>
                                {
                                  accountHasPlayers &&
                                  <button
                                    id='copyPlayer'
                                    type="button"
                                    onClick={copyPlayers}
                                    className="ml-4 inline-flex justify-center sm:justify-start w-full sm:w-auto px-4 py-2 items-center border border-green-400 text-white rounded-md shadow-sm bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white-500"
                                >
                                    <ClipboardCopyIcon className="h-5 w-5 sm:m-auto sm:p-auto" aria-hidden="true" />
                                    <span className='mr-1'>Copiar Plantilla del Torneo Anterior</span>
                                </button>
                                }
                            </>
                        }

                        {
                            canAddCoach && 
                            <>
                                {/* <button
                                    id='addCoach'
                                    type="button"
                                    className="ml-5 inline-flex items-center border border-black rounded-full shadow-sm text-black bg-white-600 hover:bg-white-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white-500"
                                >
                                    <Link to={`/${cup.permalink}/coach/new/#`} >
                                        <PlusSmIconSolid className="h-5 w-5" aria-hidden="true" />
                                    </Link>
                                </button>
                                <label htmlFor='addCoach' className="ml-3 block text-sm font-medium text-gray-700">
                                    <Link to={`/${cup.permalink}/coach/new/#`} >
                                        Agregar entrenador
                                    </Link>
                                </label> */}
                                <button
                                    id='addCoach'
                                    type="button"
                                    onClick={() => { redirect(`/${cup.permalink}/coach/new/#`) }}
                                    className="inline-flex justify-center sm:justify-start w-full sm:w-auto mt-4 px-4 py-2 sm:ml-4 sm:mt-0 items-center border border-green-400 text-white rounded-md shadow-sm bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white-500"
                                >
                                    <PlusSmIconSolid className="h-5 w-5 sm:m-auto sm:p-auto" aria-hidden="true" />
                                    <span className='mr-1'>Agregar entrenador</span>
                                </button>
                            </>
                        }
                       
                    </div>
                </div>
                {
                    availablePlayers > 0 &&
                    <div className='mt-2 inline-flex text-blue-400 px-2 py-1 rounded-md'>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-info" viewBox="0 0 16 16" id="IconChangeColor"> <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" id="mainIconPathAttribute"></path> </svg> */}
                        
                        <p className="inline-flex text-sm" id="is-paid-description">
                            <InformationCircleIcon className="h-5 w-5 my-auto" aria-hidden="true" />
                            {`Puedes agregar hasta ${availablePlayers} jugador${availablePlayers !== 1 ? 'es' : ''} más.`}
                        </p>
                    </div>
                }

                { account.coach &&
                    <>
                        <p className="mt-10">Entrenador</p>
                        <div className="my-1 space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <StackedList rows={[account.coach]} active={true} cupPermalink={cup.permalink} entity={"coach"}  onDelete={onDeletePlayer} season={cup.season}/>
                        </div>
                    </>
                }
                
                {   account.players && 
                    <>
                        <p className="my-5">Jugadores</p>
                        {   account.players.some(row => !row.isComplete) && 
                            <div className="mt-2 text-sm text-red-600 text-justify">
                                * Es necesario completar los registros
                            </div>
                        }
                        <div className="my-1 space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                            <StackedList rows={account.players} active={true} cupPermalink={cup.permalink} entity={"player"} onDelete={onDeletePlayer} season={cup.season}/>
                        </div>
                    </>
                }

{
                    (account.paymentType === 2 && account.canPayExtraPlayer) && 
                    <ExtraPlayerSection
                        account={account}
                        cup={cup}
                    />
                    
                }
            </div>
        </>
    );
}
 
export default ManageTeamSection;