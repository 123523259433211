import React, { useMemo } from "react";
import PrivacyAbt from "../containers/PrivacyAbt";
import landing from "../resources/landing.json";
import Navbar from "../components/NavbarEcyd";
import Footer from "../containers/FooterEcyd";

export default function Privacy() {
  const menu = useMemo(
    () =>
      landing.menu.map((m) => ({
        ...m,
        url: `/${m.url.includes("#") ? m.url : `${m.url.slice(1)}`}`,
      })),
    []
  );
  return (
    <>
      <div className="max-w-5xl mx-auto px-4 sm:px-6">
      <Navbar logoUrl={landing.logoUrl} menuSections={menu} />

        <PrivacyAbt />
        <Footer
        logoUrl={landing.logoUrl}
        menuSections={landing.menu}
        showNavbar={true}
      />
      </div>

    </>
  );
}
