import Footer from "../containers/FooterEcyd";
import Navbar from "../components/NavbarEcyd";
import landing from "../resources/landing.json";

import WhoAreWeSection from "../containers/WhoAreWeSection";
import { useMemo } from "react";

const AboutPage = () => {
  const menu = useMemo(
    () => landing.menu.map((m) => ({ ...m, url: `/${m.url.includes("#") ? m.url : `${m.url.slice(1)}`}` })),
    []
  );
  const photo = `${window.location.origin}/about_ecyd.jpeg`;

  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6">
      <Navbar logoUrl={landing.logoUrl} menuSections={menu} />
      <div>
        <section id="about" className="bg-primary">
          <WhoAreWeSection />
          <div className="bg-secondary overflow-hidden rounded-lg m-auto ">
            <img className="m-auto" width="75%" src={photo} alt={"logo_pokal"}/>
          </div>
        </section>
      </div>
      <Footer
        logoUrl={landing.logoUrl}
        menuSections={landing.menu}
        showNavbar={true}
      />
    </div>
  );
};

export default AboutPage;
