import Input from "../components/Input";
import { useCup } from "../context/cup-context";
import useForm from "../hooks/UseForm";
import { titleEmail, titleNumber, validateEmail, validateName, validateNumber } from "../utils/validations";

const AccountForm = ({accountUser, isPaid, onSubmit, categories=[]}) => {
    const {form, teamName, categoryUuid, playersCount,
        name, lastName, secondLastName, email, phone,
        onInputChange, onInputChangeNumber} = useForm(accountUser)

    const disabled = isPaid ? 'disabled' : null

    const onSubmitButton = e => {
        onSubmit(e, form)
    }

    const { getCupColor } = useCup();

    return (  
        <>
            <div className="mt-10 md:mt-5 md:col-span-2">
                <form onSubmit={onSubmitButton}>
                    <div className="shadow overflow-hidden sm:rounded-md">
                        <div className="px-4 py-5 bg-white sm:p-6">
                            <h3 className="text-3xl text-bold">
                                〉Información del equipo <hr />
                            </h3>
                            <div className="grid grid-cols-6 gap-6 mt-5 mb-14">
                                <Input
                                    divClassName="col-span-6 sm:col-span-6 lg:col-span-2"
                                    label="Nombre de equipo"
                                    name="teamName"
                                    value={teamName}
                                    disabled={disabled}
                                    onChange={onInputChange}
                                    required="required"
                                    pattern={validateName}
                                />

                               {
                                categories && 
                                <Input
                                    divClassName="col-span-6 sm:col-span-3 lg:col-span-2"
                                    label="Categoría"
                                    options={categories}
                                    attributeName="name"
                                    name="categoryUuid"
                                    type="select"
                                    value={categoryUuid}
                                    disabled={disabled}
                                    onChange={onInputChange}
                                    required="required"
                                />
                               }

                                <Input
                                    divClassName="col-span-6 sm:col-span-3 lg:col-span-2"
                                    label="Número de jugadores"
                                    name="playersCount"
                                    type="number"
                                    min="1"
                                    value={playersCount}
                                    disabled={true}
                                    onChange={onInputChangeNumber}
                                    required="required"
                                    pattern={validateNumber}
                                    title={titleNumber}
                                />
                            </div>


                            <h3 className="text-3xl text-bold">
                                〉Información de la cuenta <hr />
                            </h3>
                            <div className="grid grid-cols-6 gap-6 mt-5">
                                <Input
                                    divClassName="col-span-6 sm:col-span-6 lg:col-span-2"
                                    label="Nombre(s) del responsable"
                                    name="name"
                                    value={name}
                                    disabled={disabled}
                                    onChange={onInputChange}
                                    required="required"
                                    pattern={validateName}
                                />

                                <Input
                                    divClassName="col-span-6 sm:col-span-3 lg:col-span-2"
                                    label="Apellido paterno del responsable"
                                    name="lastName"
                                    value={lastName}
                                    disabled={disabled}
                                    onChange={onInputChange}
                                    required="required"
                                    pattern={validateName}
                                />

                                <Input
                                    divClassName="col-span-6 sm:col-span-3 lg:col-span-2"
                                    label="Apellido materno del responsable"
                                    name="secondLastName"
                                    value={secondLastName}
                                    disabled={disabled}
                                    onChange={onInputChange}
                                    required="required"
                                    pattern={validateName}
                                />

                                <Input
                                    divClassName="col-span-6 sm:col-span-3"
                                    label="Correo"
                                    name="email"
                                    type="email"
                                    value={email}
                                    disabled={disabled}
                                    onChange={onInputChange}
                                    required="required"
                                    pattern={validateEmail}
                                    title={titleEmail}
                                    isEmail={true}
                                />

                                <Input
                                    divClassName="col-span-6 sm:col-span-3"
                                    label="Teléfono"
                                    name="phone"
                                    value={phone}
                                    disabled={disabled}
                                    onChange={onInputChange}
                                    required="required"
                                    pattern={validateNumber}
                                    title={titleNumber}
                                />
                            </div>
                        </div>
                        { !isPaid &&
                            <div className="px-4 py-3 bg-gray-50 text-center sm:text-left sm:px-6">
                                <button
                                    style={{ backgroundColor: getCupColor() }}
                                    type="submit"
                                    className="btn-pink"
                                >
                                    Guardar
                                </button>
                            </div>
                        }
                    </div>
                </form>
            </div>
        </>
    );
}
 
export default AccountForm;
