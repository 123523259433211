import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import HeadingEcyd from "../components/HeadingEcyd";
import Navbar from "../components/NavbarEcyd";
import { Attributes } from "../constants/CupData";
import Footer from "../containers/FooterEcyd";
import PlayerForm from "../containers/PlayerForm";
import SponsorSection from "../containers/SponsorSection";
import { getAccount, getPlayer } from "../lib/ApiEcyd";
import { enrollmentDatesValidation } from "../lib/helpers";
import HeaderSign from "../components/AccessSign";
import { useDetails } from "../context/details-context";
import { useCup } from "../context/cup-context";
import ErrorPage from './ErrorPage';

const contactNumber = process.env.REACT_APP_CONTACT_NUMBER;

const PlayerPage = () => {
    const { sponsorList } = useDetails();
    const {cupPermalink, playerPermalink} = useParams();
    const { cup, getCupByPermalink } = useCup();

    useEffect(() => {
        getCupByPermalink(cupPermalink);
    }, [cupPermalink, getCupByPermalink])

    const [account, setAccount] = useState({
        loading : true,
        data : {},
        hasError : null
    })

    const [player, setPlayer] = useState({
        loading : true,
        data : null,
        hasError : null
    })


    // Initial effect with charge the component
    useEffect(() => {
        getAccount(account, setAccount)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(cup.loading) return
        if(playerPermalink) {
            getPlayer(playerPermalink, player, setPlayer, cup?.contact?.phoneNumber || contactNumber);
        }
        else {
            setPlayer({
                ...player,
                loading : false
            })
        }
        // eslint-disable-next-line
    }, [cup])

  
    
    if(account.hasError) return <ErrorPage error={account.hasError} type="account"/>
    if(cup.hasError) return <ErrorPage error={cup.hasError} type="cup"/>
    if(player.hasError) return <ErrorPage error={player.hasError} type="player" link={"/" + cup.data?.permalink + "/account"}/>

    const attributes = Attributes(cup.data.permalink)

    return <div className="max-w-5xl mx-auto px-4 sm:px-6">
            {(!account.loading && !cup.loading && !sponsorList.loading && !player.loading) && (
            <>
                <HeaderSign
                  cupUuid={cup.data?.uuid}
                  seasonUuid={cup.data?.season?.uuid || ""}
                  categories={cup.data?.season?.categories}
                  enrollmentDates={enrollmentDatesValidation(cup?.data?.season)}
                /><Navbar logoUrl={cup.data.imageUrl || attributes.cupImgUrl} menuSections={attributes.menu}/>
                <HeadingEcyd sections={[account.data.teamName, 'Agregar jugador']} cupPermalink={cup.data.permalink} breadcrumb/>
                <h1 className="hidden">Copa : {cupPermalink}</h1>
                <PlayerForm account={account.data} cup={cup.data} player={player.data} method={playerPermalink ? "PUT" : "POST"}/>
                <section id="sponsors" className="mt-10 bg-secondary">
                    <SponsorSection sponsors={sponsorList.list} titleStyle={"text-title-primary text-center leading-8 tracking-tight sm:text-4xl sm:tracking-tight"}/>
                </section>
                <Footer logoUrl={cup.data.imageUrl || attributes.cupImgUrl } menuSections={attributes.menu} termsAndConditionsUrl={cup.data?.files?.termsAndConditionsDocUrl} />
            </>)
            }
        </div>
}
 
export default PlayerPage;