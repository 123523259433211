import { useState } from "react";
import Input from "./Input";
import { messageError } from "../lib/AlertsUtils";
import { getExtensionFile } from "../utils/getExtensionFile";
import { ErrorMessages } from "../constants/ErrorMessages";

const ImageSimple = ({
  src,
  alt,
  text = "",
  title,
  withFile,
  id,
  name,
  required = false,
  message = "",
  imgId,
}) => {
  const [preview, setPreview] = useState(src && `${src}?${Date.now()}`);
  const mb = 1024;
  const imageSizeValidation = (src) => {
    let img = new Image();
    img.src = window.URL.createObjectURL(src);
    img.onload = () => {
      if (img.width > img.height) {
        messageError(
          title,
          "Es necesario que la imagen sea tipo retrato (vertical)"
        );
        img.src = "";
        setPreview(false);
        return;
      }
    };
  };
  const onChange = async (e) => {
    e.preventDefault();
    const input = e.target;
    if (!input.value) return;
    imageSizeValidation(input.files[0]);
    if (input.files[0].size / mb > mb * 6) {
      messageError(title, "El tamaño de la imagen debe ser menor a 6Mb");
      input.value = "";
      input.files = [];
      return;
    }
    const ext = getExtensionFile(input.value);
    if (ext === "pdf") {
      const { title, description } = ErrorMessages.noAcceptPdf;
      messageError(title, description);
      input.value = "";
      input.idBase64 = "";
      return;
    }
    if (!["jpeg", "png", "jpg", "svg"].includes(ext)) {
      const { title, description } = ErrorMessages.imageFormat;
      messageError(title, description);
      input.value = "";
      input.idBase64 = "";
      return;
    }
    const [file] = input.files;
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
  };

  return (
    <div className="flex gap-4 h-full">
      <div className="flex justify-start  w-40">
        {preview ? (
          <img className="h-full m-auto" src={preview} alt={alt} id={imgId} />
        ) : (
          <svg
            className="h-full w-28 mr-10 border border-gray-300 bg-white text-gray-300"
            preserveAspectRatio="none"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 200 200"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeWidth={1}
              d="M0 0l200 200M0 200L200 0"
            />
          </svg>
        )}
      </div>
      <div className="inline-block text-gray-500 text-justify my-auto text-sm align-middle">
        {title && <p className="h-full text-title">{title}</p>}
        <p className="h-full">{text}</p>
        {withFile && (
          <div className="h-1/2 mt-10">
            <Input
              id={id}
              name={name}
              type="file"
              required={required}
              onChange={onChange}
              message={message}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageSimple;
