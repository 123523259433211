import { Routes, Route } from "react-router-dom";
import AccountPage from "../pages/AccountPage";
import LandingPage from "../pages/LandingPage";
import NotFoundPage from "../pages/NotFound";
import CupHomePage from "../pages/CupPage";
import DocumentPage from "../pages/DocumentPage";
import PlayerPage from "../pages/PlayerPage";
import CoachPage from "../pages/CoachPage";
import AccountActivationPage from "../pages/AccountActivationPage";
import RequestPassword from "../pages/RequestPasswordPage";
import ResetPassword from "../pages/ResetPasswordPage";
import AboutPage from "../pages/AboutPage";
import Privacy from "../pages/Privacy";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/:cupPermalink" element={<CupHomePage />} />
      <Route path="/:cupPermalink/account" element={<AccountPage />} />
      <Route
        path="/:cupPermalink/account/:token"
        element={<AccountActivationPage />}
      />
      <Route path="/:cupPermalink/coach/new" element={<CoachPage />} />
      <Route
        path="/:cupPermalink/coach/:coachPermalink"
        element={<CoachPage />}
      />
      {/* <Route path="/:cupPermalink/summon" element={<DocumentPage type='summon'/>} /> */}
      {/* <Route path="/:cupPermalink/enrollment" element={<DocumentPage type='enrollment'/>} /> */}
      <Route
        path="/:cupPermalink/document/:documentPermalink"
        element={<DocumentPage />}
      />
      {/* <Route path="/:cupPermalink/assets" element={<DocumentPage type='assets'/>} /> */}
      <Route path="/:cupPermalink/player/new" element={<PlayerPage />} />
      <Route
        path="/:cupPermalink/player/:playerPermalink"
        element={<PlayerPage />}
      />
      <Route
        path="/:cupPermalink/password/reset"
        element={<RequestPassword />}
      />
      <Route
        path="/:cupPermalink/password/reset/key/:changePasswordToken"
        element={<ResetPassword />}
      />
      <Route path="/:cupPermalink/*" element={<NotFoundPage />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRouter;
