export const Attributes = (cupPermalink='cup') => { 
    return {
        cupImgUrl:"https://pokal-webpage-resources.s3.amazonaws.com/copa-giro-sur.svg",
        menu:[
            {
                title:"Inicio",
                url:`/${cupPermalink}`
            },
            {
                title:"Copas",
                url:"/#cups"
            },
            {
                title:"Convocatoria",
                url:`/${cupPermalink}/document/summonDocUrl`
            },
            {
                title:"Proceso de inscripción",
                url:`/${cupPermalink}/document/enrollmentDocUrl`
            },
            {
                title:"Términos&Condiciones",
                url: `/${cupPermalink}/document/termsAndConditionsDocUrl`
            },
            {
                title:"Contacto",
                url:`/${cupPermalink}/#contact`
            },
        ],
        bannerImgUrl: `https://pokal.s3.amazonaws.com/${cupPermalink}/banner.svg`
    }
}

export const TIMON_CUP = "copa-timon";