import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Input from '../components/inputForm';
import Navbar from "../components/NavbarEcyd";
import Footer from "../containers/FooterEcyd";
import { Attributes } from "../constants/CupData";
import { useCup } from "../context/cup-context";
import { requestTokenResetPassword } from "../lib/ApiEcyd";
import { ErrorMessages } from "../constants/ApiErrorMessages";
import { 
    emailErrorMessage,
} from '../utils/validations';
const AccountActivationPage = () => {
    const [email, setEmail] = useState("")
    const [errorEmail, setErrorEmail] = useState("")
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)
    const { cupPermalink } = useParams();
    const { cup, getCupByPermalink } = useCup();

    useEffect(() => {
        getCupByPermalink(cupPermalink);
    }, [cupPermalink, getCupByPermalink])

    const attributes = Attributes(cupPermalink);

    const onSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        const errorEmailValidation = emailErrorMessage(email, email !== "");

        if(errorEmailValidation) {
            setErrorEmail(errorEmailValidation)
            setError(null);
            return;
        }
        setErrorEmail("")

        formData.append("cupUuid", cup.data.uuid);
        formData.append("email", e.target.email.value);

        setLoading(true);
        const response = await requestTokenResetPassword(formData);
        setLoading(false);
        if(response?.statusText === "No Content" || response?.status === 201 || response?.status === true) {
            setSuccess(true);
            setError(null);
        } else {
            setSuccess(false);
            setError(response?.data?.error?.Code || response?.status || 404 );
        }
    }

    return (
        <div className="max-w-5xl mx-auto px-4 sm:px-6 min-h-screen flex flex-col justify-between">            
            <Navbar logoUrl={cup.data.imageUrl || attributes.cupImgUrl} menuSections={attributes.menu}/>
            <main className="flex justify-center items-align items-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                <form onSubmit={onSubmit}>
                    <div className="flex flex-col justify-center items-center rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl sm:my-8 sm:max-w-sm sm:w-full sm:p-6  gap-y-3">
                        <h3 className="text-lg leading-6 font-bold text-pink-700 text-center pb-4">
                            Ayuda con la contraseña
                        </h3>
                        { success ? <p>Hemos enviado un correo electrónico a: <b>{email}
                        </b>. En unos minutos recibiras un mail, por favor revisa tu bandeja de entrada</p> :
                        <>
                            <p>Escribe la dirección de correo electrónico asociado a tu cuenta.</p>
                                <Input
                                    divClassName="w-full"
                                    initialValue={email}
                                    placeholder="Correo electrónico"
                                    id="email"
                                    name="email"
                                    type="email"
                                    required="required"
                                    setParentState={(e) => setEmail(e.target.value)}
                                    errorMessage={errorEmail}
                                    isEmail={true}
                                />
                                {error && <p className="text-red-600">{ErrorMessages.requestResetPassword[error]}</p>}
                                <button
                                    className="btn-pink position: relative mt-1.5"
                                    type="submit"
                                    disabled={loading}
                                    >
                                    Continuar
                                    { loading && <div className="loader" /> }
                                </button>
                        </>
                        }
                    </div>
                </form>
            </main>
            <Footer logoUrl={cup.data.imageUrl || attributes.cupImgUrl } menuSections={attributes.menu} />
        </div>
    );
}
 
export default AccountActivationPage;
