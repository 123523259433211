import ContactSection from "../containers/ContactSection";
import ContentWithImage from "../containers/ContentWithImage";
import Footer from "../containers/FooterEcyd";
import GallerySection from "../containers/GallerySection";
import GridCups from "../containers/GridCups";
import Navbar from "../components/NavbarEcyd";
import NewsSection from "../containers/NewsSection";
import SponsorSection from "../containers/SponsorSection";
import landing from "../resources/landing.json";

import { useDetails } from "../context/details-context";

const LandingPage = () => {
    const { cupList, sponsorList } = useDetails();

    return ( 
        <div className="max-w-5xl mx-auto px-4 sm:px-6">
            <Navbar logoUrl={landing.logoUrl} menuSections={landing.menu}/>
            <div>
                <section id="home" className="bg-primary">
                    <ContentWithImage title={landing.home.title} description={landing.home.text} image={landing.home.imageUrl} season={undefined}/>
                </section>
                <section id="cups" className="bg-secondary">
                    <GridCups cups={cupList.list} title={landing.cups.title} description={landing.cups.text} />
                </section>
                {/* <section id="us" className="bg-primary">
                    <UsSection instagramUser={landing.us.igUsername} instagramUrl={landing.us.igUrl} videoUrl={landing.us.videoUrl} />
                </section> */}
                <section id="contact" className="bg-primary">
                    <ContactSection contactPhone={landing.contact.phone} showBall={true} showSocialNetworks={false}/>
                </section>
                <section id="gallery" className="bg-secondary">
                    <GallerySection gallery={landing.gallery}/>
                </section>
                <section id="news" className="bg-primary">
                    <NewsSection title={landing.news.title} description={landing.news.text} news={landing.news.list} />
                </section>
                <section id="sponsors" className="bg-secondary mb-10">
                    <SponsorSection sponsors={sponsorList.list} titleStyle={"text-title-primary text-center leading-8 tracking-tight sm:text-4xl sm:tracking-tight"}/>
                </section>
            </div>
            <Footer logoUrl={landing.logoUrl} menuSections={landing.menu} showNavbar={true}/>
        </div>
     );
}
 
export default LandingPage;