const ImageSimple = ({
    src, alt,
    text="", title,
    imgWidthClass = 'w-28',
    imgHeightClass = 'h-full',
    divClass="flex h-full gap-x-5"
    }) => {

    const time = !src?.startsWith('blob') ? "?" + Date.now() : "";
    
    return (  

        <div className={divClass}>
            <div className='flex justify-start '>
                <img
                        src={`${src}${time}`}
                        className={`${imgHeightClass} ${imgWidthClass} m-auto shadow border border-gray-100`}
                        alt={alt}
                />
            </div>
            <div className="inline-block text-gray-500 text-justify my-auto text-sm align-middle">
                {   title &&
                    <p className="h-full text-title">{title}</p>
                }
                <p className="h-full">{text}</p>
            </div>
        </div>
    );
}
 
export default ImageSimple;