import { useContext, createContext, useState, useEffect } from "react";
import { getCup } from "../lib/ApiEcyd";

const initialState = {
    cup: {
        loading: true,
        hasError: null,
        data: {}
    },
};

const CupContext = createContext(initialState);

export const useCup = () => useContext(CupContext);

export default function CupProvider({ children }) {
    const [cupPermalink, setCupPermalink] = useState('');

    const [cup, setCup] = useState({
        loading: true,
        data: {},
        hasError: null,
    });

    useEffect(() => {
        async function fetchData() {
            if(!cupPermalink) return;
            const data = await getCup(cupPermalink);
            if(data.success) {
                setCup({
                    loading : false,
                    data: data.result,
                    hasError: null
                });
            } else {
                setCup({
                    loading : false,
                    hasError: data
                });
            }
        }
        fetchData();
    }, [cupPermalink])

    const getCupByPermalink = (newPermailink) => {
        if(newPermailink === cupPermalink) return;
        setCupPermalink(newPermailink);
    }

    const getCupColor = () => cup.data?.files ? (cup.data.files?.mainTheme ? `#${cup.data.files?.mainTheme}` : "#db2777") : "#db2777";

    const getCupActiveSession = () => {
      const session = sessionStorage.getItem("cupPermalink");
      if (session !== null) {
        const data = session.split("#");
        return {
          name: data[1],
          permalink: data[0],
        };
      }
      return session;
    };
    return (
        <CupContext.Provider value={{ cup, getCupByPermalink, getCupColor, getCupActiveSession, setCup, setCupPermalink }}>
            {children}
        </CupContext.Provider>
    );
}