import Ball from './../assets/ball.png'
import whatsLogo from './../assets/whatsapp-logo.svg'
import instaLogo from './../assets/insta-logo.svg'
import facebookLogo from './../assets/facebook-logo.svg'
import downloadIos from './../assets/downloadIos.png'
import downloadAndroid from './../assets/downloadAndroid.png'

const ContactSection = ({contactPhone='', showBall, showSocialNetworks, fbUrl='', igUrl='', appStoreAppUrl='', playStoreAppUrl=''}) => {
    return (  
        <>  
            {showBall ?
                <div className="text-center">
                    <img
                        className="mt-7 inline-block rounded-full"
                        src={Ball}
                        alt=""
                    />
                </div>
                : 
                <div></div>
            }
            <div className="relative pb-5 pt-10">
                <h2 className="text-title-secondary text-center leading-8 tracking-tight sm:text-1xl sm:tracking-tight">
                    Contacto y Apps
                </h2>
                <p className="text-lg mt-4 w-1/3 text-primary mx-auto text-center">
                    Comunícate con nosotros para cualquier duda o comentario al <a className='underline' href={`tel: ${{contactPhone}}`}>{contactPhone}</a>
                </p>
            </div>
            {
                showSocialNetworks ? 
                <div className="text-center mb-8">
                    <a href={`${process.env.REACT_APP_WHATSAPP_CONTACT_URL}?phone=${contactPhone}&text=Hola,%20tengo%20una%20duda...`} rel='noreferrer' target='_blank'>
                        <img
                            className="mt-7 inline-block rounded-full"
                            src={whatsLogo}
                            alt="whats-logo"
                        />
                    </a>
                    <a href={fbUrl} rel='noreferrer' target='_blank'>
                        <img
                        className="mt-7 inline-block rounded-full"
                        src={facebookLogo}
                        alt="facebook-logo"
                        />
                    </a>   
                    <a href={igUrl} rel='noreferrer' target='_blank'>
                        <img
                            className="mt-7 inline-block rounded-full"
                            src={instaLogo}
                            alt="insta-logo"
                        />
                    </a>    
                </div>
                : 
                <div className="text-center mb-8">
                    <a href={`${process.env.REACT_APP_WHATSAPP_CONTACT_URL}?phone=${contactPhone}&text=Hola,%20tengo%20una%20duda...`} rel='noreferrer' target='_blank'>
                        <img
                            className="w-16 mt-7 inline-block rounded-full"
                            src={whatsLogo}
                            alt="whats-logo"
                        />
                    </a>
                </div>
            }
            {
                appStoreAppUrl && playStoreAppUrl &&
                <div className="text-center mb-24">
                    <a href={appStoreAppUrl} rel='noreferrer' target='_blank'>
                        <img
                            className="mt-5 inline-block rounded-full"
                            src={downloadIos}
                            alt="download-ios-logo"
                        />
                    </a>
                    <br></br> 
                    <a href={playStoreAppUrl} rel='noreferrer' target='_blank' className="w-32">
                        <img
                            className="mt-5 inline-block rounded-full"
                            src={downloadAndroid}
                            alt="download-android-logo"
                        />
                    </a>
                </div>
            }   
        </>
    );
}
 
export default ContactSection;