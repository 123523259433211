import { messageAlert } from "../lib/AlertsUtils";
import BoletiaInfo from "./BoletiaInfo";
 
const InfoCards = ({dates='', categories='', inscriptionDates='', modalData = undefined}) => {
    const onShowInscriptions = (inscriptions) => {
        messageAlert(
            "Apertura de Registros por Categoría", // title
            <BoletiaInfo />, // text
            "info", // type
            true, // showConfirmButton
            "Entendido", // buttonText
            0, // timer = 0 - so it does not close until user does
        );
    };

    const infoItems = [
        {
            id: 1,
            title:"Fecha",
            description:`${dates}`,
            imageSrc: 'https://pokal-webpage-resources.s3.amazonaws.com/dates.svg',
            imageAlt: 'dates',
        },
        {
            id: 2,
            title:"Categorías",
            description:`${categories}`,
            imageSrc: 'https://pokal-webpage-resources.s3.amazonaws.com/categories.svg',
            imageAlt: 'categories',
        },
        {
            id: 3,
            title:"Inscripciones",
            description:`${inscriptionDates}`,
            imageSrc: 'https://pokal-webpage-resources.s3.amazonaws.com/enrollment.svg',
            imageAlt: 'enrollment',
            ...(typeof modalData === 'object' && {component: () => (
                <div>
                    <span className="text-sm text-gray-400">{inscriptionDates}</span>
                    <button onClick={() => onShowInscriptions(modalData)} className="text-sm bg-white text-sm hover:underline text-blue-800 font-semibold p-1 py-0">
                        Ver más...
                    </button>
                </div>
            )})
        },
    ];

    return ( 
        <div className="mt-10 pb-12 bg-white sm:pb-16">
            <div className="relative">
            <div className="absolute" />
                <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="max-w-4xl mx-auto">
                        <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3">
                            {infoItems.map((item, i) => (
                                <div key={i} className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-black-500">
                                        <img
                                            src={item.imageSrc}
                                            alt={item.imageAlt}
                                            className="w-16 h-16 sm:w-16 sm:h-16 float-left"
                                        />
                                        <p className="text-lg">{item.title}</p>
                                        {!item?.component ? <p className="text-sm text-gray-400">{item.description}</p> : item.component()}
                                    </dt>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfoCards;
